import { useState, useEffect, useRef, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Divider, TextField, IconButton, Container, CircularProgress } from '@mui/material';
import { textfield, button, bottomSection } from './ChatBoxStyles';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Constants from "./Constants.jsx";
import "./ChatBoxStyles.scss";
import TechHiveAPIService from "../../services/TechHiveAPIService.js";
import { useSelector, useDispatch } from 'react-redux';
import { setChatId, setSelectedChatId, clearSelectedSets, newChat } from '../../state/index';
import { ThreeDots } from 'react-loader-spinner';
import { updateMessages, resetMessages } from '../../state/index';
import { AlertContext } from '../../AlertContext.jsx';
import { ChatHistoryService, setChatHistory } from '../../services/PromptAPIservice';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function ChatBox() {
    // Styling
    const theme = useTheme();
    const dispatch = useDispatch();
    const TextFieldStyles = textfield();
    const buttonStyles = button();
    const bottomSectionStyles = bottomSection();
    const [loading, setLoading] = useState(false);

    const techHiveAPIService = new TechHiveAPIService();
    const chatHistoryService = new ChatHistoryService();
    const messages = useSelector((state) => state.global.messages);
    const [isWaiting, setIsWaiting] = useState(false);
    const [currentMessage, setCurrentMessage] = useState("");
    const [statusMessage, setStatusMessage] = useState(
        Constants.Messages.WaitingMessage
    );
    const selectedItem = useSelector((state) => state.global.selectedSets);
    const firstRender = useRef(true);
    const settings = useSelector((state) => state.global.settings);
    const selectedChatId = useSelector((state) => state.global.selectedChatId);
    const { setAlert } = useContext(AlertContext);
    const userData = useSelector((state) => state.global.userProfileData);
    const selectedId = useSelector((state) => {
        if (state.global && state.global.selectedSets && state.global.selectedSets[0]) {
            return state.global.selectedSets[0].id;
        }
        return null;
    });
    const chatId = useSelector((state) => state.global.selectedChatId);
    const chatIds = useSelector((state) => state.api.chatIds);

    const mode = useSelector((state) => state.global.mode);

    const bubbleStyles = {
        backgroundColor: mode === 'dark' ? '#333333' : '#d8d8d8',
        color: mode === 'dark' ? '#ffffff' : '#1d1c1c',
    };

    useEffect(() => {
        if (selectedItem.length > 0) {
            addMessage(Constants.CSSClassNames.BotMessageClass, "You have switched to the following persona:\n" + selectedItem[0].personaTitle);
        }
        else if (settings.model == 'GPT35TDEPLOY20230717' || settings.model == 'gpt-4' || settings.model == 'gpt-3.5-turbo' || settings.model == 'gpt-4-1106-preview') {
            addMessage(Constants.Messages.WelcomeMessage, "Explore GenAI directly or select a persona from the sidebar to provide prompts");
        }
    }, [selectedItem, settings.model]);

    // Scroll to bottom
    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if (!firstRender.current && userData) {
            setLoading(true);
            fetchChat(userData.email, selectedChatId);
        }
    }, [selectedChatId]);

    const fetchChat = async function (email, selectedChatId) {
        try {
            const res = await chatHistoryService.getChat(email, selectedChatId);
            dispatch(resetMessages());
            res.forEach(message => {
                const inputMessage = JSON.parse(message.inputMessage);
                const outputMessage = message.outputMessage;

                if (inputMessage.some(msg => msg.role === 'user')) {
                    addMessage(Constants.CSSClassNames.UserMessageClass, inputMessage[1].content);
                }
                addMessage(Constants.CSSClassNames.BotMessageClass, outputMessage);
            });
            setLoading(false);
        }
        catch (error) {
            console.error('error', error);
        }
    }

    const messagesEndRef = useRef(null);

    // Handlers
    const handleMessage = (event) => {
        setCurrentMessage(event.currentTarget.value);
    };

    const handleSend = () => {
        if (currentMessage.length === 0) return;
        if (isWaiting) {
            setStatusMessage(Constants.Messages.WaitingMessageWarning);
        } else {
            sendMessageEvent();
        }
    };

    const handleEnterKey = (e) => {
        if (e.key === Constants.Keys.Enter && currentMessage.length === 0) return;
        if (e.key === Constants.Keys.Enter && isWaiting) {
            e.preventDefault();
            setStatusMessage(Constants.Messages.WaitingMessageWarning);
        } else if (e.key === Constants.Keys.Enter && !e.shiftKey) {
            sendMessageEvent();
        }
    };

    const sendMessageEvent = () => {
        addMessage(Constants.CSSClassNames.UserMessageClass, currentMessage);
        callAPI(currentMessage.replace(/\n$/, ''));
        setCurrentMessage("");
        setStatusMessage(Constants.Messages.WaitingMessage);
    };

    const addMessage = (type, message) => {
        dispatch(updateMessages({ type, message }));
    };

    const callAPI = async (message) => {
        setIsWaiting(true);

        if (settings.model == 'GPT35TDEPLOY20230717' || settings.model == 'gpt-4' || settings.model == 'gpt-3.5-turbo' || settings.model == 'gpt-4-1106-preview') {
            let msgContent = '';
            if (selectedItem.length > 0) {
                selectedItem[0].personaText.map((item, ind) => {
                    msgContent += ind + 1 + '.' + item + '. '
                })
            }
            let contextMessage = [{ role: "system", content: msgContent }];
            const chatCompletionRequest = {
                model: settings.model,
                messages: contextMessage,
                stop: "",
                temperature: "0.5",
                user: userData.email,   
                personaId: selectedId,
                chatId: chatId
            };
            let outgoingMessage = { role: "user", content: message };

            chatCompletionRequest.messages.push(outgoingMessage);

            let responseMessage = await techHiveAPIService.getChatCompletion(
                chatCompletionRequest
            );

            dispatch(setSelectedChatId(chatId));
            addMessage(Constants.CSSClassNames.BotMessageClass, responseMessage);

            if (!chatIds.some(chat => chat.chatId === chatId)) {
                setChatHistory(chatHistoryService, userData.email, dispatch);
            }
        }
        else {
            const data = {
                Model: settings.model,
                MaxTokens: settings.maxTokens.toString(),
                Temperature: settings.temperature.toString(),
                Stream: false,
                User: userData.email,
                FrequencyPenalty: settings.frequencyPenalty.toString(),
                PresencePenalty: settings.presencePenalty.toString(),
                Stop: settings.stopIndicator,
                PromptEndIndicator: settings.promptEndIndicator,
                Prompt: message,
            };
            let responseMessage = await techHiveAPIService.getCompletion(data);
            addMessage(Constants.CSSClassNames.BotMessageClass, responseMessage);
        }
        setIsWaiting(false);
    };


    return (

        <Box sx={{ width: '100%', padding: 3, paddingTop: 2, position: 'relative', height: 'calc(100vh - 75px)' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontFamily: 'poppins', paddingBottom: 1, paddingLeft: 1, fontSize: 14 }}>Generative AI Conversation</Typography>
                <Typography sx={{ fontFamily: 'poppins', paddingBottom: 1, marginLeft: 'auto', fontSize: 14 }}>Active Model: {settings.model}</Typography>
            </Box>

            <Divider></Divider>


            <div className="chatBox">
                <Container className="chat-history">
                    {loading ? (
                        <Stack>
                            <Skeleton variant="text" sx={{ fontSize: '2rem', width: '270px' }} />
                            <Skeleton variant="text" sx={{ fontSize: '2rem', width: '270px' }} />
                            <Skeleton variant="text" sx={{ fontSize: '2rem', width: '370px', marginLeft: 'auto !important', backgroundColor: '#0095C880' }} />
                            <Skeleton variant="text" sx={{ fontSize: '2em', width: '370px' }} />
                            <Skeleton variant="text" sx={{ fontSize: '2rem', width: '470px' }} />
                            <Skeleton variant="text" sx={{ fontSize: '2rem', width: '470px' }} />
                            <Skeleton variant="text" sx={{ fontSize: '2rem', width: '270px' }} />
                            <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px', marginLeft: 'auto !important', backgroundColor: '#0095C880' }} />
                        </Stack>
                    ) : (
                            messages.map((item, ind) => (
                                <div
                                    key={ind}
                                    className={
                                        item.type === Constants.CSSClassNames.UserMessageClass
                                            ? `message ${"message-right"}`
                                            : "message"
                                    }
                                >
                                    <div
                                        className={
                                            item.type === Constants.CSSClassNames.UserMessageClass
                                                ? `chat-bubble right text ${"bubble-blue"}`
                                                : `chat-bubble text ${"bubble-white"}` 
                                        }
                                        style={item.type !== Constants.CSSClassNames.UserMessageClass ? bubbleStyles : undefined}
                                    >
                                        {item.message}
                                    </div>
                                </div>
                            ))
                        )}
                    <div ref={messagesEndRef} />
                </Container>

            </div>

            <Box sx={{ ...bottomSectionStyles, background: theme.palette.background.default }}>
                <TextField sx={{
                    ...TextFieldStyles,
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '10px',
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.textfield.focused,
                        }
                    },                    
                    '& .MuiInputLabel-outlined.Mui-focused': {
                        color: theme.palette.text.secondary,
                    }
                }}
                    id="standard-multiline-flexible"
                    label={currentMessage ? ("") : ("Type something")}
                    value={currentMessage}
                    onChange={handleMessage}
                    onKeyUp={handleEnterKey}
                    spellcheck="false"
                    minRows={1}
                    maxRows={3}
                    multiline
                    size='small'
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                        endAdornment: (
                            <ThreeDots
                                height="30"
                                width="30"
                                radius="10"
                                color="#DA291C"
                                ariaLabel="three-dots-loading"
                                visible={isWaiting}
                            />
                        ),
                    }}
                />
                <IconButton disabled={(!currentMessage || isWaiting)} disableElevation onClick={handleSend} sx={{ ...buttonStyles }}>
                    <SendRoundedIcon />
                </IconButton>
            </Box>

        </Box>
    );
}