import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
import globalReducer from "./state";
import apiReducer from './services/PromptAPIservice';
import { Provider } from 'react-redux';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";
import { BrowserRouter as Router } from 'react-router-dom';

const msalInstance = new PublicClientApplication(msalConfig);

const store = configureStore({
  reducer: {
    global: globalReducer,
    api: apiReducer,
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);
