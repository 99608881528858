import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, TextField, Box, useTheme, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateSettings, clearSelectedSets, resetMessages, setSelectedChatId } from '../../state/index.jsx';
import { fonttype } from '../../components/FineTune/FineTuneStyles.jsx';
import TechHiveAPIService from '../../services/TechHiveAPIService.js';

const Settings = ({ open, setOpen }) => {
    const fonttypeStyle = fonttype();

    const theme = useTheme();
    const dispatch = useDispatch();
    const model = useSelector((state) => state.global.settings.model);
    const temperature = useSelector((state) => state.global.settings.temperature);
    const maxTokens = useSelector((state) => state.global.settings.maxTokens);
    const frequencyPenalty = useSelector((state) => state.global.settings.frequencyPenalty);
    const presencePenalty = useSelector((state) => state.global.settings.presencePenalty);
    const stopIndicator = useSelector((state) => state.global.settings.stopIndicator);
    const promptEndIndicator = useSelector((state) => state.global.settings.promptEndIndicator);

    const techHiveAPIService = new TechHiveAPIService();

    const [localTemperature, setLocalTemperature] = useState(temperature);
    const [localMaxTokens, setLocalMaxTokens] = useState(maxTokens);
    const [localFrequencyPenalty, setLocalFrequencyPenalty] = useState(frequencyPenalty);
    const [localPresencePenalty, setLocalPresencePenalty] = useState(presencePenalty);
    const [localModel, setLocalModel] = useState(model);
    const [localStopIndicator, setLocalStopIndicator] = useState(stopIndicator);
    const [localPromptEndIndicator, setLocalPromptEndIndicator] = useState(promptEndIndicator);
    const [modelsList, setModelsList] = useState([]);
    const userData = useSelector((state) => state.global.userProfileData);
    const [isModelLoading, setIsModelLoading] = useState(true);


    useEffect(() => {
        setLocalTemperature(temperature);
        setLocalMaxTokens(maxTokens);
        setLocalFrequencyPenalty(frequencyPenalty);
        setLocalPresencePenalty(presencePenalty);
        setLocalModel(model);
        setLocalStopIndicator(stopIndicator);
        setLocalPromptEndIndicator(promptEndIndicator);
    }, [open, temperature, maxTokens, frequencyPenalty, presencePenalty, model, stopIndicator, promptEndIndicator]);


    const handleTemperatureChange = (event, newValue) => {
        if (event.type == 'change') {
            if (/^(0(\.\d{0,2})?|1(\.0{0,2})?)?$/.test(event.target.value)) {
                setLocalTemperature(event.target.value);
            }
        }
        else {
            setLocalTemperature(newValue);
        }
    };

    const handleMaxTokensChange = (event, newValue) => {
        if (event.type == 'change') {
            if(event.target.value === '') {
                setLocalMaxTokens('');
            }
            if (/^([0-9]{1,3}|2\d{3}|204[0-7]|2048)$/.test(event.target.value)) {
                setLocalMaxTokens(event.target.value);
            }
        }
        else {
            setLocalMaxTokens(newValue);
        }        
    };

    const handleFrequencyPenaltyChange = (event, newValue) => {
        if (event.type == 'change') {
            if (/^(0(\.\d{0,2})?|1(\.\d{0,2})?|2(\.0{0,2})?)?$/.test(event.target.value)) {
                setLocalFrequencyPenalty(event.target.value);
            }
        }
        else {
            setLocalFrequencyPenalty(newValue);
        }        
    };

    const handlePresencePenaltyChange = (event, newValue) => {
        if (event.type == 'change') {
            if (/^(0(\.\d{0,2})?|1(\.\d{0,2})?|2(\.0{0,2})?)?$/.test(event.target.value)) {
                setLocalPresencePenalty(event.target.value);
            }
        }
        else {
            setLocalPresencePenalty(newValue);
        }        
    };

    const handleModelChange = (event) => {
        const newValue = event.target.value;
        setLocalModel(newValue);
    };

    const handleStopIndicatorChange = (event) => {
        const newValue = event.target.value;
        setLocalStopIndicator(newValue);
    };

    const handlePromptEndIndicatorChange = (event) => {
        const newValue = event.target.value;
        setLocalPromptEndIndicator(newValue);
    };

    const generateRandomChatId = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let token = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          token += characters.charAt(randomIndex);
        }
        return token;
      };

    const handleSaveClick = () => {
        dispatch(updateSettings({ key: 'temperature', value: localTemperature }));
        dispatch(updateSettings({ key: 'maxTokens', value: localMaxTokens }));
        dispatch(updateSettings({ key: 'frequencyPenalty', value: localFrequencyPenalty }));
        dispatch(updateSettings({ key: 'presencePenalty', value: localPresencePenalty }));
        if (localModel != model) {
            dispatch(updateSettings({ key: 'model', value: localModel }));
            dispatch(clearSelectedSets());
            dispatch(resetMessages());
            dispatch(setSelectedChatId(generateRandomChatId(8)));
        }
        dispatch(updateSettings({ key: 'stopIndicator', value: localStopIndicator }));
        dispatch(updateSettings({ key: 'promptEndIndicator', value: localPromptEndIndicator }));
        setOpen(false);
    };

    useEffect(() => {
        if(open) {
            callModelsAPI();
        }        
    }, [userData, open]);

    const callModelsAPI = async () => {
        if (userData) {
            setIsModelLoading(true);
            let responseMessage = await techHiveAPIService.fetchModels(userData.email);
            let azureModel = {
                'id': 'GPT35TDEPLOY20230717',
                'createdAt': '1693550789',
                'owner': 'Azure'
            }
            responseMessage.data.data.push(azureModel);
            setModelsList(responseMessage.data.data);
            setIsModelLoading(false);
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{ sx: { width: "50%", maxHeight: "470px" } }}
            >

                <DialogTitle sx={{ fontSize: 18, fontFamily: 'poppins', fontWeight: '400', marginTop: '-3px', padding: 2, paddingLeft: 3 }}>Settings
                    <IconButton className="iconButton" sx={{ fontSize: '15px', marginTop: '-3px', float: 'right' }}
                        onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Box sx={{ marginLeft: '20px' }}>
                    <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                        <Box sx={{ marginLeft: '30px', justifyContent: 'space-evenly' }}>
                            <Typography noWrap={true} sx={{ ...(fonttypeStyle), color: theme.palette.font.main, marginTop: 2.5 }}>Model:</Typography>
                            {isModelLoading ? (<CircularProgress size={20} sx={{ marginLeft: '370px' }} />) :
                                (<> <FormControl size='small' sx={{
                                    minWidth: 200,
                                    width: '70px',
                                    justifyContent: 'center',
                                    marginLeft: '300px',
                                    marginTop: -2.7,
                                    '& .MuiOutlinedInput-root': {
                                      '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.textfield.focused,
                                      },
                                    },
                                    '& .MuiInputLabel-outlined.Mui-focused': {
                                      color: theme.palette.textfield.focused,
                                    },
                                  }}>
                                    <InputLabel id="model-select-label">Model</InputLabel>
                                    <Select
                                        labelId="model-select-label"
                                        id="model-select"
                                        value={localModel}
                                        label="Model"
                                        onChange={handleModelChange}
                                        MenuProps={{
                                            sx: {
                                              "& .Mui-selected": {
                                                backgroundColor: theme.palette.navbar.default
                                              }
                                            }
                                          }} 
                                    >
                                        {modelsList.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.id}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </>)}
                        </Box>
                        <Box sx={{ marginLeft: '30px', justifyContent: 'space-evenly' }}>
                            <Typography noWrap={true} sx={{ ...(fonttypeStyle), color: theme.palette.font.main }}>Temperature: </Typography>
                            <Slider
                                size='small'
                                sx={{ width: '200px' }}
                                value={localTemperature}
                                min={0}
                                max={1}
                                step={0.01}
                                onChange={handleTemperatureChange}
                                color='error'
                            />
                            <TextField size='small' sx={{
                                width: '200px', justifyContent: 'center', marginLeft: '100px', '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.textfield.focused,
                                    }
                                },
                                '& .MuiInputLabel-outlined.Mui-focused': {
                                    color: theme.palette.textfield.focused,
                                },
                            }} value={localTemperature} onChange={handleTemperatureChange} variant="outlined" />
                        </Box>
                        <Box sx={{ marginLeft: '30px', justifyContent: 'space-evenly' }}>
                            <Typography noWrap={true} sx={{ ...(fonttypeStyle), color: theme.palette.font.main }}>Max Tokens: </Typography>
                            <Slider
                                size='small'
                                sx={{ width: '200px', size: 'small' }}
                                value={localMaxTokens}
                                min={0}
                                max={2048}
                                step={1}
                                onChange={handleMaxTokensChange}
                                color='error'
                            />
                            <TextField size='small' sx={{
                                width: '200px', justifyContent: 'center', marginLeft: '100px', '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.textfield.focused,
                                    }
                                },
                                '& .MuiInputLabel-outlined.Mui-focused': {
                                    color: theme.palette.textfield.focused,
                                },
                            }} value={localMaxTokens} onChange={handleMaxTokensChange} variant="outlined" />
                        </Box>
                        <Box sx={{ marginLeft: '30px', justifyContent: 'space-evenly' }}>
                            <Typography noWrap={true} sx={{ ...(fonttypeStyle), color: theme.palette.font.main }}>Frequency Penalty: </Typography>
                            <Slider
                                size='small'
                                sx={{ width: '200px' }}
                                value={localFrequencyPenalty}
                                min={0}
                                max={2}
                                step={0.01}
                                onChange={handleFrequencyPenaltyChange}
                                color='error'
                            />
                            <TextField size='small' sx={{
                                width: '200px', justifyContent: 'center', marginLeft: '100px', '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.textfield.focused,
                                    }
                                },
                                '& .MuiInputLabel-outlined.Mui-focused': {
                                    color: theme.palette.textfield.focused,
                                },
                            }} value={localFrequencyPenalty} onChange={handleFrequencyPenaltyChange} variant="outlined" />
                        </Box>
                        <Box sx={{ marginLeft: '30px', justifyContent: 'space-evenly' }}>
                            <Typography noWrap={true} sx={{ ...(fonttypeStyle), color: theme.palette.font.main }}>Presence Penalty: </Typography>
                            <Slider
                                size='small'
                                sx={{ width: '200px' }}
                                value={localPresencePenalty}
                                min={0}
                                max={2}
                                step={0.01}
                                onChange={handlePresencePenaltyChange}
                                color='error'
                            />
                            <TextField size='small' sx={{
                                width: '200px', justifyContent: 'center', marginLeft: '100px', '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.textfield.focused,
                                    }
                                },
                                '& .MuiInputLabel-outlined.Mui-focused': {
                                    color: theme.palette.textfield.focused,
                                },
                            }} value={localPresencePenalty} onChange={handlePresencePenaltyChange} variant="outlined" />
                        </Box>
                        <Box sx={{ marginLeft: '30px', justifyContent: 'space-evenly', marginTop: 2 }}>
                            <Typography noWrap={true} sx={{ ...(fonttypeStyle), color: theme.palette.font.main }}>Stop:</Typography>
                            <TextField
                                size='small'
                                sx={{
                                    width: '200px', justifyContent: 'center', marginLeft: '300px', marginTop: -2.7, '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: theme.palette.textfield.focused,
                                        }
                                    },
                                    '& .MuiInputLabel-outlined.Mui-focused': {
                                        color: theme.palette.textfield.focused,
                                    },
                                }}
                                value={localStopIndicator}
                                variant="outlined"
                                onChange={handleStopIndicatorChange}
                            />
                        </Box>
                        <Box sx={{ marginLeft: '30px', justifyContent: 'space-evenly', marginTop: 1 }}>
                            <Typography noWrap={true} sx={{ ...(fonttypeStyle), color: theme.palette.font.main }}>Prompt End Indicator:</Typography>
                            <TextField
                                size='small'
                                sx={{
                                    width: '200px', justifyContent: 'center', marginLeft: '300px', marginTop: -2.7, '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: theme.palette.textfield.focused,
                                        }
                                    },
                                    '& .MuiInputLabel-outlined.Mui-focused': {
                                        color: theme.palette.textfield.focused,
                                    },
                                }}
                                value={localPromptEndIndicator}
                                variant="outlined"
                                onChange={handlePromptEndIndicatorChange}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Button variant="containedBitwiseButton" disableElevation sx={{ marginBottom: '20px' }} onClick={handleSaveClick}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

export default Settings
