import * as React from 'react';
import { useContext } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, List, ListItemButton } from '@mui/material';
import { fonttype, fonttypeHeader, accordionHeader } from './SidebarStyles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSet, unselectSetID, toggleFavorite, unselectFavorite } from '../../state/index.jsx';
import { useEffect, useState } from 'react';
import { AlertContext } from '../../AlertContext.jsx';
import { PromptAPIservice, fetchSystemData, fetchData } from '../../services/PromptAPIservice';
import { useTheme, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material';
import AddPrompt from '../DialogBox/AddPrompt';
import ConfirmationBox from '../DialogBox/ConfirmationBox';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarRateIcon from '@mui/icons-material/StarRate';

export default function SystemPromptSets() {
  // Styling
  const theme = useTheme();
  const fonttypeStyle = fonttype(theme);
  const fonttypeHeaderStyle = fonttypeHeader();
  const accordionHeaderStyle = accordionHeader();
  const iconButtonStyles = {
    fontSize: '15px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  // Initializations
  const dispatch = useDispatch();
  const data = useSelector((state) => state.api.systemData);
  const codeFunction = useSelector((state) => state.global.function);
  const promptAPIservice = new PromptAPIservice();
  const userProfileData = useSelector((state) => state.global.userProfileData);
  const user_id = userProfileData.id;
  const [openPrompts, setOpenPrompts] = useState(false);
  const [currentTitle, setCurrentTitle] = useState({});
  const [currentGoals, setCurrentGoals] = useState({});
  const selectedItems = useSelector((state) => state.global.selectedSets);
  const [openClone, setOpenClone] = useState(false);
  const [cloneItem, setCloneItem] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const model = useSelector((state) => state.global.settings.model);

  // Handlers
  const handleBoxClick = (item) => {
    if (model == 'GPT35TDEPLOY20230717' || model == 'gpt-4' || model == 'gpt-3.5-turbo' || model == 'gpt-4-1106-preview') {
      const isSelected = selectedItems.includes(item);
      if (isSelected) {
        dispatch(unselectSetID(item.id));
      } else {
        dispatch(toggleSet(item));
      }
    }
    else {
      setAlert({ open: true, severity: 'error', message: 'Persona can only be selected for chat completion models' });
    }
  }

  const handleDialogClick = (title, goals, event) => {
    event.stopPropagation();
    setCurrentTitle(title);
    setCurrentGoals(goals);
    setOpenPrompts(true);
  }

  const handleCloneClick = (item, event) => {
    event.stopPropagation();
    setOpenClone(true);
    setCloneItem(item);
  }

  const clickCloneButtonHandler = async function (promptId, event) {
    setOpenClone(false);
    try {
      const res = await promptAPIservice.promptClone(promptId, user_id, userProfileData.email);
      setAlert({ open: true, severity: 'success', message: 'Persona cloning successful' });
      fetchData(user_id, promptAPIservice, dispatch, userProfileData.email);
    }
    catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Persona cloning failed' });
      console.error('Code cloning failed:', error);
    }
  }
  
  const handleFavoriteClick = async (item, event) => {
    event.stopPropagation();
    const data = {
      id: item.id,
      favourite: item.favourite ? false : true
    }
    try {
      const res = await promptAPIservice.personaFavourite(data, userProfileData.email);
      if (data.favourite == true) {
        setAlert({ open: true, severity: 'success', message: 'Added to Favourites' });
      }
      else {
        setAlert({ open: true, severity: 'success', message: 'Removed from Favourites' });
      }
    }
    catch (error) {
      console.error('Persona favourite failed:', error);
      setAlert({ open: true, severity: 'error', message: 'Favourites update failed' });
    }
    fetchSystemData(promptAPIservice, dispatch, userProfileData.email);
  }

  return (
    <div>
      <Accordion defaultExpanded={true} sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.font.main }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ ...(accordionHeaderStyle), backgroundColor: theme.palette.accordion.header }}
        >
          <Typography sx={{ ...(fonttypeHeaderStyle), color: theme.palette.font.main }}>SYSTEM PERSONAS</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: theme.palette.accordion.content, padding: 0 }}>

          {data
            .map((item, index) => (
              <React.Fragment key={item.id}>
                <Box sx={{ display: 'flex', width: '100%', maxWidth: 200, justifyContent: 'flex-start', '&:hover .iconButton': { visibility: 'visible' } }}>
                  <List disablePadding>
                    <ListItemButton onClick={() => handleBoxClick(item)}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '260px',
                        height: '35px',
                        display: 'flex',  
                        justifyContent: 'space-between', 
                        alignItems: 'center', 
                        // backgroundColor: selectedItems.includes(item) ? theme.palette.action.selected : 'transparent',
                        backgroundColor: selectedItems.some(selectedItem => selectedItem.id === item.id)
                        ? theme.palette.action.selected
                        : 'transparent',
                      '&:hover': {
                        '& .hoverBox': {
                          maxWidth: '67%',
                        },
                      },
                      }}>
                      {/* <Typography sx={{ ...(fonttypeStyle), color: theme.palette.font.main }}>{item.personaTitle}</Typography> */}
                      <Box className="hoverBox" sx={{ maxWidth: '100%', display: 'flex', gap: 0.2, justifyContent: 'flex-end', }}>
                      <Typography noWrap={true}
                        sx={{
                          ...(fonttypeStyle),
                          color: theme.palette.font.main,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {item.personaTitle}</Typography>
                    </Box>

                      <Box sx={{
                        display: 'flex', gap: 0.2, width: '100%', justifyContent: 'flex-end',
                        '& .iconButton': {
                          visibility: 'hidden',
                        },
                      }}>

                        <IconButton className="iconButton" size="small" sx={{
                          ...iconButtonStyles,
                          right: '2.2rem',
                        }}
                          onClick={(event) => handleFavoriteClick(item, event)}>
                          {item.favourite ? <StarRateIcon fontSize="extra-small" /> : <StarBorderIcon fontSize="extra-small" />}
                        </IconButton>

                        <IconButton className="iconButton" size="small" sx={{
                          ...iconButtonStyles,
                          right: '3.9rem',
                        }} onClick={(event) => handleDialogClick(item.personaTitle, item.personaText, event)}>
                          <VisibilityIcon fontSize="extra-small" />
                        </IconButton>

                        <IconButton className="iconButton" size="small" sx={{
                          ...iconButtonStyles,
                          right: '0.5rem',
                        }}
                          onClick={(event) => handleCloneClick(item, event)}>
                          <ContentCopyIcon fontSize="extra-small" />
                        </IconButton>


                      </Box>
                    </ListItemButton>
                  </List>
                </Box>
              </React.Fragment>
            ))}
          <AddPrompt title={'System Personas'} disabled={true} open={openPrompts} setOpen={setOpenPrompts} currentTitle={currentTitle} currentGoals={currentGoals} system={true} />
          {cloneItem
            ? <ConfirmationBox open={openClone} setOpen={setOpenClone} item={cloneItem} handler={clickCloneButtonHandler} handlerArgs={cloneItem.id} title='Clone Prompt' message={
              <span>
                Are you sure you want to clone{' '}
                <strong>{cloneItem.personaTitle}</strong>?
              </span>
            } />
            : null
          }
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
