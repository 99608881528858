import React, { useState } from 'react'
import { AlertContext } from '../../AlertContext'
import Navbar from '../../components/Navbar/Navbar'
import { Box, Alert, Snackbar } from '@mui/material'
import Footer from '../../components/Footer/Footer'
import Sidebar from '../../components/Sidebar/Sidebar'
import WelcomePage from '../WelcomePage/WelcomePage'
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom'

const Layout = ({ signOut }) => {
  const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });
  const location = useLocation();
  const allowedRoutes = ['/home', '/finetune', '/'];

  const isWelcomePage = location.pathname === '/welcome';
  const shouldShowSidebar = allowedRoutes.includes(location.pathname);

  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      <Snackbar open={alert.open} autoHideDuration={5000} onClose={() => setAlert({ ...alert, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ top: '6.5vh' }}>
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Box width="100%" height="100%">
        <Box>
          <Navbar signOut={signOut} />
          {isWelcomePage ? (
            <Outlet />
          ) : (
              <>
                {shouldShowSidebar && <Sidebar />}
                {!shouldShowSidebar && <Outlet />}
              </>
            )}
          <Footer />
        </Box>
      </Box>
    </AlertContext.Provider>
  )
}

export default Layout