import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, Box, Typography, IconButton, Divider, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { rowFiles } from './FineTuneStyles';
import { FineTuneApiService } from '../../services/PromptAPIservice';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { radio, fontsize, fonttype } from './FineTuneStyles';
import ReplayIcon from '@mui/icons-material/Replay';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const FileList = ({ open, setOpen, email, baseModel, uploadType, sendDataToParent }) => {

    const theme = useTheme();
    const [events, setEvents] = useState([]);
    const rowFilesStyles = rowFiles();
    const fineTuneApiService = new FineTuneApiService();
    const [selectedValue, setSelectedValue] = useState('');
    const [loading, setLoading] = useState(false);

    const fonttypeStyle = fonttype(theme);
    const radioStyle = radio();

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        if (open) {
            fetchFileList(email);
        }
    }, [open]);

    const fetchFileList = async (email) => {
        setLoading(true);
        try {
            const res = await fineTuneApiService.fileList(email);
            const invertedUserFiles = res.userFiles.reverse();
            setEvents(invertedUserFiles);
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const refreshFile = async (id) => {
        const retrievalData = await fineTuneApiService.fileRetrieval(id, email);
        if (retrievalData.status == 'processed') {
            fetchFileList(email);
        }
    };

    const handleClose = () => {
        setOpen(false);
        sendDataToParent();
    };

    const submitHandler = () => {
        setOpen(false);
        sendDataToParent(events.find((item) => item.fileId === selectedValue));
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { width: "40%", maxHeight: "470px" } }}
            >

                <DialogTitle sx={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '400', marginTop: '-3px', padding: 2, paddingBottom: 0, paddingLeft: 3 }}>File list for <b>{baseModel}</b>
                    <IconButton className="iconButton" sx={{ fontSize: '15px', marginTop: '-3px', float: 'right' }}
                        onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Box sx={{ margin: '0px 20px 0px 20px', paddingBottom: '10px' }}>
                    <Typography sx={{ fontFamily: 'inter', fontSize: '13px', lineHeight: '20px', padding: 0 }}>Total Files: <span><strong>{events
                        .filter((item) => item.baseModel === baseModel).filter((item) => item.uploadType === uploadType).length}</strong></span></Typography>
                    <Divider sx={{ marginBottom: '5px', marginTop: '5px' }}></Divider>
                    <Box sx={{ color: theme.palette.text.primary, maxHeight: 300, overflowY: 'auto' }}>

                        {loading ? (
                            <Box>
                                <Stack spacing={1.5}>
                                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                                    <Divider></Divider>
                                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                                    <Divider></Divider>
                                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                                    <Divider></Divider>
                                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                                </Stack>
                            </Box>
                        ) : (
                                <Box>
                                    <RadioGroup value={selectedValue} onChange={handleRadioChange}>
                                        {events
                                            .filter((item) => item.baseModel === baseModel)
                                            .filter((item) => item.uploadType === uploadType)
                                            .map((item, index) => {
                                                return (
                                                    <>
                                                        <div key={item.fileId} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div key={item.fileId}>
                                                                <FormControlLabel
                                                                    value={item.fileId}
                                                                    disabled={item.status != 'processed'}
                                                                    control={<Radio sx={radioStyle} />}
                                                                    label={<Typography sx={{ ...(fonttypeStyle), color: theme.palette.font.main, fontSize: 14, fontWeight: '400' }}>{item.fileName}</Typography>}
                                                                />
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'inter',
                                                                        fontSize: '13px',
                                                                        lineHeight: '20px',
                                                                        paddingLeft: '33px',
                                                                    }}
                                                                >
                                                                    Status: {item.status}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'inter',
                                                                        fontSize: '13px',
                                                                        lineHeight: '20px',
                                                                        paddingLeft: '33px',
                                                                    }}
                                                                >
                                                                    FileId: {item.fileId}
                                                                </Typography>
                                                            </div>
                                                            <div>
                                                                {item.status != 'processed' && <IconButton size="small" className="iconButton" onClick={() => { refreshFile(item.fileId) }} sx={{ fontSize: '15px', marginRight: '10px' }}>
                                                                    <ReplayIcon fontSize="small" />
                                                                </IconButton>}
                                                            </div>
                                                        </div>
                                                        <Divider sx={{ marginBottom: '5px', marginTop: '5px' }}></Divider>
                                                    </>
                                                );
                                            })}
                                    </RadioGroup>
                                </Box>
                            )}

                    </Box>
                    {selectedValue && <Box sx={{ display: 'flex', justifyContent: 'center' }}><Button onClick={submitHandler} size='small' sx={{ margin: '0px', marginTop: '5px', float: 'right' }} variant="containedBitwiseButton">Submit</Button></Box>}

                </Box>
            </Dialog>
        </>
    )
}

export default FileList
