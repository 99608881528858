import React from 'react';
import FineTune from '../../components/FineTune/FineTune'
import { Box } from "@mui/material";

const FinetunePage = () => {
    return (
        <div>
            <Box display="flex" position="relative">
                <FineTune />
			</Box>            
        </div>
    )
}

export default FinetunePage
