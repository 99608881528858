import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { Box, useTheme } from "@mui/material";
import logo from './logo.svg';

const FallbackPage = ({signOut}) => {
    const theme = useTheme();

    return (
        <div style={{ height: '100vh', }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}>
                <img src={logo} style={{height: '200px'}} alt='logo' />
                <h2 style={{textAlign: 'center' , fontFamily:'poppins'}}>Page not found!</h2>
            </Box>
        </div>
    )
}

export default FallbackPage
