import react from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography, Button, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setCondAccepted } from '../../state';

function WelcomeBody({ signIn }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleAgree = () => {
    navigate('/home');
  }

  const redirectToHive = () => {
    window.location.href = 'https://bitwiseglobal0.sharepoint.com/sites/TechHive/SitePages/TechHive-Playground.aspx';
  }


  return (
    <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <Box>
        <h2 style={{ textAlign: 'center', fontFamily: 'poppins' }}>TechHive GenAI Playground - Disclaimer</h2>
        <Box sx={{
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '8px',
          padding: '16px',
          width: '700px',
          margin: 'auto',
          marginTop: '10px',
          textAlign: 'center',
          backgroundColor: theme.palette.primary.main,
        }}>
          <Typography
            variant="h5"
            align="center"
            sx={{ fontWeight: 'bold', marginBottom: '16px', fontFamily: 'poppins', fontSize: '20px' }}
          >
            Notice
            </Typography>
          <Typography sx={{ marginBottom: '16px', fontFamily: 'poppins', fontSize: '15px' }}>By accessing and using TechHive Playground, you agree that you will not submit any personal data or personally identifiable information (PII), personal health data, or other sensitive or regulated data into this Playground.
            </Typography>
          <Typography sx={{ fontFamily: 'poppins', fontSize: '15px' }}>
            We take information security very seriously, and expect all employees to comply with our policies
            in order to protect our organization from potential risks. Any violations of our policies will
            result in disciplinary action.
            </Typography>
          <hr style={{ borderTop: `1px solid` }} />
          <Typography sx={{ fontFamily: 'poppins', fontSize: '15px' }}>Note: The messages being sent to TechHive GenAI Playground are logged for analytical purposes</Typography>
          <Button variant="containedBitwiseButton" onClick={signIn} disableElevation sx={{ marginTop: '20px' }}>I agree</Button>
          <Button variant="contained" onClick={redirectToHive} disableElevation sx={{ marginTop: '20px', marginLeft: '10px', fontFamily: 'poppins' }}>Disagree</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default WelcomeBody;