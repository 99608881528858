import React from 'react'
import { Box, useTheme } from "@mui/material";
import SystemPromptSets from './SystemPromptSets';
import UserPromptSets from './UserPromptSets';
import FavouritePersona from './FavouritePersona';


const PromptSets = () => {
  const theme = useTheme();
  return (
    <div>
    <Box sx={{flexGrow: 1, overflowX: 'hidden', overflowY: 'auto', height: 'calc(95vh - 120px)' }}>
      <FavouritePersona />
      <SystemPromptSets />
      <UserPromptSets />
    </Box>
    </div>
  )
}

export default PromptSets