import * as React from 'react';
import { useContext, useState } from 'react';
import { useEffect } from "react";
import { Button, Dialog, DialogTitle, TextField, Box, Container, useTheme, Tooltip, Typography, IconButton } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { userPrompt } from './DialogBoxStyles';
import { useDispatch, useSelector } from 'react-redux';
import { PromptAPIservice, fetchData } from '../../services/PromptAPIservice';
import { AlertContext } from '../../AlertContext.jsx';
import * as Yup from 'yup';
import ShareIcon from '@mui/icons-material/Share';
import { Divider } from '@mui/material';
import { WorkTwoTone, Share, UploadFileOutlined } from '@mui/icons-material';
import PublishIcon from '@mui/icons-material/Publish';

function AddPrompt({ title, method, disabled, open, setOpen, currentTitle, currentGoals, promptId, system }) {
  // Styling
  const theme = useTheme();
  const userPromptStyle = userPrompt();
  const userData = useSelector((state) => state.global.userProfileData);

  // Initializations
  const user_id = userData.id;
  const useremail = userData.email;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.api.data);
  const promptAPIservice = new PromptAPIservice();
  const { setAlert } = useContext(AlertContext);
  const [url, setUrl] = useState('');

  const [initialValues, setInitialValues] = useState({
    title: currentTitle ? currentTitle : '',
    goals: currentGoals ? currentGoals : [''],
  });

  const formik = useFormik({
    initialValues,
  });

  const validationSchema = Yup.object({
    title: Yup.string().required('Title cannot be empty'),
    goals: Yup.array()
      .of(
        Yup.string()
          .required('Goal cannot be empty')
      )
      .required('At least one goal is required')
      .min(1, 'At least one goal is required')
  });

  useEffect(() => {
    setInitialValues({
      title: currentTitle ? currentTitle : '',
      goals: currentGoals ? currentGoals : ['']});
  }, [open]);

  // Submit form handler
  const clickSubmitButtonHandler = async (method, userId, values, promptId) => {
    if(method == "Edit"){
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      const newPrompt = {
        id : promptId,
        personaTitle: values.title,
        personaText: values.goals,
        createdAt: formattedDate,
        personaAuthor: userData.name,
      };
      setOpen(false);
      try {
          const res = await promptAPIservice.promptUpdate(newPrompt, useremail);
          fetchData(user_id, promptAPIservice, dispatch);
          setAlert({ open: true, severity: 'success', message: 'Persona updating successful' });
      }
      catch(error){
          setAlert({ open: true, severity: 'error', message: 'Persona updating failed' });
          console.error('Code updating failed:', error);
      }
    }
    else {
      if (data.some(item => item.personaTitle === values.title)) {
        setAlert({ open: true, severity: 'error', message: 'Please add a persona with a different title' });
      }
      else {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const newPrompt = {
          personaTitle: values.title,
          personaText: values.goals,
          createdAt: formattedDate,
          promptAuthor: 'Name',
        };
        setOpen(false);
        try {
          const res = await promptAPIservice.promptCreate(userId, newPrompt, useremail);
          fetchData(user_id, promptAPIservice, dispatch, useremail);
          setAlert({ open: true, severity: 'success', message: 'Persona creating successful' });
        }
        catch (error) {
          setAlert({ open: true, severity: 'error', message: 'Persona creating failed' });
          console.error('Prompt creating failed:', error);
        }
        fetchData(user_id, promptAPIservice, dispatch, useremail);
      }
    }
  }

  const sharePersona = async () => {
    const currentGoalsString = currentGoals.join('\n');
  
    const personaData = {
      personaTitle: currentTitle,
      personaText: currentGoalsString.split('\n').map(goal => goal.trim())
    };
  
    const jsonData = JSON.stringify(personaData);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const urlForResultFile = URL.createObjectURL(blob);
    setUrl(urlForResultFile);
  
    await new Promise((resolve) => setTimeout(resolve, 0));
  
    const link = document.createElement('a');
    link.href = urlForResultFile;
    link.download = currentTitle + '.json';
    link.click();
  };
  
  const uploadPersona = (event) => {
    const file = event.target.files[0];
  
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const content = e.target.result;
        try {
          const parsedContent = JSON.parse(content);
  
          if (!parsedContent.personaTitle) {
            setAlert({ open: true, severity: 'error', message: 'Error parsing JSON file: Invalid format' });
          } else {
            const filteredPersonaText = parsedContent.personaText.filter(text => text.trim() !== '');
            const transformedObject = {
              title: parsedContent.personaTitle,
              goals: filteredPersonaText,
            };
            setInitialValues(transformedObject);
          }
        } catch (error) {
          setAlert({ open: true, severity: 'error', message: 'Error parsing JSON file: Invalid JSON format' });
        }
      };  
      reader.readAsText(file);
    }
  };
  

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {setOpen(false)}}
        PaperProps={{ sx: { ...(userPromptStyle) } }}
      >

        <DialogTitle sx={{ fontSize: 18, fontFamily: 'poppins', fontWeight: '400', marginTop: '-3px', padding:2, paddingLeft: 3 }}>{title}
        <IconButton className="iconButton" sx={{ fontSize: '15px',  marginTop: '-3px',float: 'right' }}
            onClick={() => {setOpen(false)}}>
            <CloseIcon />
        </IconButton>

        {method == 'Edit' && <Tooltip placement='top' title='Share prompt via json file'>
        <IconButton className="iconButton" sx={{  marginTop: '-3px', marginRight:'10px', float: 'right' }}
          onClick={() => sharePersona()} >
            <ShareIcon />
        </IconButton>
        </Tooltip>}

        {method == 'Add' && <Tooltip placement='top' title='Upload persona via json file'>
        <IconButton component="label" sx={{  marginTop: '-3px', marginRight:'10px', float: 'right' }} >
            <PublishIcon  />
            <input type="file" accept=".json" onChange={uploadPersona} style={{ display: 'none' }} />
        </IconButton>
        </Tooltip>}

        </DialogTitle>

        <Container>
          <Formik
            enableReinitialize
            initialValues={initialValues}          
            validationSchema={validationSchema}
            onSubmit={values => {
              clickSubmitButtonHandler(method, user_id, values, promptId);
            }}

            render={({ values }) => (
              <Form>
                <Box width="100%" paddingBottom='5px'>
                  <ErrorMessage name="title" render={msg => <div style={{ color: 'red', fontSize: '0.7rem', marginLeft: '30px' }}>{msg}</div>} />
                  <Box display="flex" alignItems="center">
                    <Field name="title">
                      {({ field }) => <TextField disabled={disabled} size="small"{...field} label="Title"
                      // value={Title}
                      sx={{
                        width: system ? '85%': '75%',
                        marginLeft: '5%',
                        marginBottom: '5px',
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: theme.palette.textfield.focused,
                          }
                        },
                        '& .MuiInputLabel-outlined.Mui-focused': {
                          color: theme.palette.textfield.focused,
                        },
                      }}
                      />}
                    </Field>
                  </Box>
                </Box>

                <Box display='flex' width="100%" paddingTop='15px' paddingBottom='0px'>
                  <Box flexGrow={1} marginTop='-15px' paddingBottom='30px'>
                    <FieldArray
                      name="goals"
                      render={arrayHelpers => (
                        <div >
                          <div style={{ maxHeight: '220px', overflowY: 'auto' }} >
                            {values.goals && values.goals.length > 0 ? (
                              values.goals.map((placeholder, index) => (
                                <div key={index}>
                                  <Box width="100%" >

                                    <ErrorMessage name={`goals[${index}]`} render={msg => <div style={{ color: 'red', fontSize: '0.7rem', marginLeft: '30px', marginBottom: '-10px' }}>{msg}</div>} />

                                    <Box display="flex" alignItems="center">
                                      <Field name={`goals.${index}`} >
                                        {({ field }) => <TextField disabled={disabled} size="small" {...field} multiline label={`Goal ${index + 1}`} sx={{
                                          width: system ? '85%': '75%',
                                          marginLeft: '5%',
                                          marginTop: '8px',
                                          marginBottom: '5px',
                                          marginRight: '5%',
                                          '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                              borderColor: theme.palette.textfield.focused,
                                            }
                                          },
                                          '& .MuiInputLabel-outlined.Mui-focused': {
                                            color: theme.palette.textfield.focused,
                                          }
                                        }}
                                        />}
                                      </Field>
                                      {!system && index !== 0 &&
                                        <Button variant="contained" sx={{ marginTop: '8px' }}
                                          onClick={() => arrayHelpers.remove(index)}
                                        >
                                          <DeleteOutlineIcon />
                                        </Button>}
                                      {!system && index === 0 &&
                                        <Tooltip title='At least one goal is required'>
                                          <span>
                                            <Button disabled={values.goals.length < 2} variant="contained" sx={{ marginTop: '8px' }}
                                              onClick={() => arrayHelpers.remove(index)}
                                            >
                                              <DeleteOutlineIcon />
                                            </Button>
                                          </span>
                                        </Tooltip>}
                                    </Box>
                                  </Box>

                                </div>
                              ))
                            ) : (
                                <>
                                  <Field name={`goals.0`} >
                                    {({ field }) => <TextField disabled={disabled} size="small" {...field} label={`Goal 1`} sx={{
                                      width: '80%', marginLeft: '1%', marginTop: '8px', marginRight: '5%',
                                      '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                          borderColor: theme.palette.textfield.focused,
                                        }
                                      },
                                      '& .MuiInputLabel-outlined.Mui-focused': {
                                        color: theme.palette.textfield.focused,
                                      }
                                    }}
                                    />}
                                  </Field>
                                  <Button variant="contained" sx={{ marginTop: '8px' }}
                                    onClick={() => arrayHelpers.push('')}
                                  >
                                    <AddIcon />
                                  </Button>
                                </>
                              )}
                          </div>

                          {!system &&
                          <Button variant="contained" size='small' sx={{ width: '70%', marginLeft: '8%', marginTop: '15px'}}
                            onClick={() => arrayHelpers.push('')}
                          >
                            <Typography sx={{ fontSize: '14px', fontFamily: 'poppins' }}>Add new goal</Typography> <AddIcon sx={{ marginLeft: 1 }} />
                          </Button>}


                        </div>

                      )}
                    />
                  </Box>
                </Box>
                {!system &&
                <Box sx={{display: 'flex', justifyContent: 'center'}}><Button size='small' sx={{ margin: '15px', marginTop: '-10px',  }} type="submit" variant="containedBitwiseButton"><Typography sx={{ fontSize: '14px', fontFamily: 'poppins' }}>Save Promptset</Typography></Button></Box>}
                
              </Form>
            )}
          />
        </Container>
      </Dialog>
    </>

  );
}

export default AddPrompt;