import { Container, TextField, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import { Search } from "@mui/icons-material";

export default function SearchBar({ data, dispatchTo, placeholder }) {
  // Styling
  const theme = useTheme();

  // Initializations
  const dispatch = useDispatch();
  
  // Handlers
  const handleChange = (event) => {
    const filteredData = data.filter(item =>
      item.personaTitle.toLowerCase().includes(event.target.value.toLowerCase())
    )
    dispatch(dispatchTo(filteredData));
  };

  return (
    <Container sx={{paddingBottom:'15px'}}>
      <TextField
        id="search"
        type="search"
        placeholder={placeholder}
        autoComplete="off"
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <SearchIcon sx={{color: theme.palette.text, opacity: '0.5', marginRight: '2px', fontSize: '15px'}}></SearchIcon>
          ),
        }}
        sx={{
          '& .MuiInputBase-root': {
            height: '28px',
            width: '225px',
            transform: 'translate(-10px, 8px)',
            background: theme.palette.searchbar.background,
            fontSize: '13px',
          },
          '& .MuiInputLabel-root': {
            transform: 'translate(-2px, 14px)',
          },
          '& .MuiOutlinedInput-root': {
            paddingLeft: '6px',
          }
        }}
      />

        
    </Container>
  );
}