import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Button, Dialog, DialogTitle, Box, useTheme, Typography, IconButton, CircularProgress } from "@mui/material";
import { FineTuneApiService } from "../../services/PromptAPIservice"
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';


const FileProgress = ({ open, setOpen, fineTuneId, emailId, status }) => {

    const theme = useTheme();
    const fineTuneApiService = new FineTuneApiService();
    const [events, setEvents] = useState([]);
    const mode = useSelector((state) => state.global.mode);
    const [rows, setRows] = useState([]);
    const [url, setUrl] = useState('');
    const [classificationAccuracy, setClassificationAccuracy] = useState('');
    const [isFileContentLoading, setIsFileContentLoading] = useState(false);
    const [fileId, setFileId] = useState('');

    useEffect(() => {
        if (open) {
            setClassificationAccuracy('');
            setUrl('');
            getFineTuneEvents(fineTuneId, emailId);
            getFineTune(fineTuneId, emailId);
        }
    }, [open]);

    useEffect(() => {
        setIsFileContentLoading(true);
        if (open && events) {
            getResultFileContent();
        }
    }, [events]);

    const headCells = [
        {
            id: 'no',
            numeric: false,
            label: 'No.',
            width: '3%',
            firstColumn: true
        },
        {
            id: 'createdTimestamp',
            numeric: true,
            label: 'Created timestamp',
            width: '28%',
            firstColumn: false
        },
        {
            id: 'level',
            numeric: true,
            label: 'Level',
            width: '3%',
            firstColumn: false
        },
        {
            id: 'message',
            numeric: false,
            label: 'Message',
            width: '33%',
            firstColumn: false
        },
        {
            id: 'data',
            numeric: false,
            label: 'Data',
            width: '33%',
            firstColumn: false
        }
    ];

    const getFineTune = async (fineTuneId, emailId) => {
        try {
            const res = await fineTuneApiService.getFineTune(fineTuneId, emailId);
            if(res.data.status == 'succeeded') {
                setFileId(res.data.resultFiles[0]);
            }            
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getFineTuneEvents = async (fineTuneId, emailId) => {
        try {
            const res = await fineTuneApiService.getFineTuneEvents(fineTuneId, emailId);
            setEvents(res.data.events);
            setRows(res.data.events);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    const getResultFileContent = async () => {
        if (status === 'succeeded') {            
            if (fileId) {
                let fileContent = await fineTuneApiService.getFileContent(fileId, emailId);
                let rows = fileContent.content.split("\n");
                let arrOfRowToValuesMap = [];
                let propColumnHeader = rows[0].split(",");

                for (let i = 1; i < rows.length - 1; i++) {
                    let rowToValuesMap = new Map();
                    let values = rows[i].split(",");
                    for (let j = 0; j < propColumnHeader.length; j++) {
                        rowToValuesMap.set(propColumnHeader[j], values[j]);
                    }
                    arrOfRowToValuesMap.push(rowToValuesMap);
                }
                let lastResult = arrOfRowToValuesMap[arrOfRowToValuesMap.length - 1].get("classification/accuracy");
                
                let resultInPercentage = Number(Number(lastResult).toPrecision(3)) * 100;
                console.log(resultInPercentage);
                setClassificationAccuracy(resultInPercentage + '%');
                const urlForResultFile = "data:text/plain;charset=utf-8," + encodeURIComponent(fileContent.content);
                setUrl(urlForResultFile);
            }
        }
        setIsFileContentLoading(false);
    };



    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{ sx: { minWidth: "70%", maxHeight: "90vh" } }}
            >

                <DialogTitle sx={{ fontSize: 18, fontFamily: 'poppins', fontWeight: '400', marginTop: '-3px', padding: 2, paddingBottom: 0, paddingLeft: 3 }}>Fine-tune history events
                    <IconButton className="iconButton" sx={{ fontSize: '15px', marginTop: '-3px', float: 'right' }}
                        onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Box sx={{ marginLeft: '20px', marginRight:'20px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 1, paddingRight: 8, marginBottom: '5px' }}>
                        {fineTuneId && <Tooltip placement="right" enterDelay={1000} title={fineTuneId}><Typography sx={{ fontFamily: 'inter', fontSize: '14px', maxWidth: '80%', whiteSpace: 'nowrap', textOverflow: 'ellipsis',overflow: 'hidden'}}>Fine-tuned Model: {fineTuneId}</Typography></Tooltip>}
                        {isFileContentLoading && status === 'succeeded' ? (
                            <CircularProgress size={20} sx={{ marginLeft: 1, color: 'black' }} />
                        ) : (
                                url && status === 'succeeded' && (
                                    <Typography sx={{ fontFamily: 'inter', fontSize: '14px' }}>
                                        <Button size='small' sx={{ margin: '0px', width: '120px', padding: '4px' }} download={'compiled_results.csv'} href={url} variant="containedBitwiseButton"><Typography sx={{ fontSize: '11px', fontFamily: 'poppins' }}>Download Result</Typography></Button>
                                    </Typography>
                                )
                            )}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 1, paddingRight: 8, marginBottom: '7px' }}>
                        {status && <Typography sx={{ fontFamily: 'inter', fontSize: '14px' }}>Status: {status.charAt(0).toUpperCase() + status.slice(1)}</Typography>}
                        {isFileContentLoading && status === 'succeeded' ? (
                            <CircularProgress size={20} sx={{ marginLeft: 1, color: 'black' }} />
                        ) : (
                                status === 'succeeded' && <Typography sx={{ fontFamily: 'inter', fontSize: '14px' }}>Accuracy:{" " + classificationAccuracy}</Typography>)
                        }
                    </Box>

                    {/* {isFileContentLoading && <CircularProgress size={20} sx={{ marginLeft: 1 }} />} */}



                    <Box sx={{ width: '100%', marginBottom: '10px' }}>
                        <div style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
                            <TableContainer >
                                <Table sx={{ minWidth: 700 }} aria-labelledby="tableTitle" size="small">
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: mode === 'dark' ? 'black' : '#f5f5f5' }}>
                                            {headCells?.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    width={headCell.width}
                                                    align='left'
                                                    padding='none'
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: 'bold',
                                                        fontSize: '12px',
                                                        padding: '5px 15px 5px 5px'
                                                    }}
                                                >

                                                    <Typography variant="subtitle1" sx={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: '12px', marginLeft: headCell.firstColumn ? '5px' : '-5px'}}>
                                                        {headCell.label}
                                                    </Typography>

                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>
                        
                        <div style={{ maxHeight: '54vh', overflow: 'auto' }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 700 }} aria-labelledby="tableTitle" size="small">
                                    <TableBody>
                                        {rows?.map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            let date = new Date(0);
                                            return (
                                                <TableRow
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.level}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        width="3%"
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ marginLeft: '10px', paddingLeft: '15px' }}
                                                    >
                                                        <Typography variant="body1" sx={{
                                                            fontFamily: 'Inter', fontSize: '12px', maxWidth: '200px',
                                                            overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                                        }}>
                                                            {(index + 1)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell width="28%" align="left">
                                                        <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '12px', }}>
                                                            {new Date(date.setUTCSeconds(row.createdAt)).toLocaleString('en-US', {
                                                                day: 'numeric',
                                                                month: 'long',
                                                                year: 'numeric',
                                                                hour: 'numeric',
                                                                minute: 'numeric',
                                                                second: 'numeric',
                                                                hour12: true
                                                            })}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell width="3%" align="left">
                                                        <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '12px'}}>
                                                            {row.level}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell width="33%" align="left">
                                                        <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '12px'}}>
                                                            {row.message}
                                                        </Typography>

                                                    </TableCell>

                                                    <TableCell width="33%" align="left">
                                                        <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '12px'}}>
                                                        
                                                        {row.data ? <>Trainloss: {row.data.trainLoss} <br></br> Train mean token accuracy: {row.data.trainMeanTokenAccuracy}</> : 'NA'}
                                                            
                                                        </Typography>

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

export default FileProgress
