import { BrowserRouter as Router, Route, Routes, Navigate, Switch } from 'react-router-dom'
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeSettings } from "./theme.jsx";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import Home from "./views/Home/Home";
import Layout from "./views/Layout/Layout";
import WelcomePage from './views/WelcomePage/WelcomePage.jsx';
import FinetunePage from './views/Finetune/FinetunePage';
import FallbackPage from './views/404/FallbackPage';
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest, redirectUrl } from "./config/authConfig";
import { useNavigate, useLocation } from "react-router-dom";
import ApiConfig from "./config/ApiConfig.json";
import { callMsGraph } from './graph';
import { setUserProfileData, setModelList } from "./state/index.jsx";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import { PromptAPIservice, ChatHistoryService , fetchData, fetchSystemData, setFilteredData, setChatHistory } from './services/PromptAPIservice';

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { instance } = useMsal();
  const userData = useSelector((state) => state.global.userProfileData);
  const [isUserOnBoarded, setIsUserOnboarded] = useState(false);
  const promptAPIservice = new PromptAPIservice();
  const chatHistoryService = new ChatHistoryService();

  const [routeExists, setRouteExists] = useState(true);

   //Registering redirect promise on msal instance for handling of login response
   instance.handleRedirectPromise()
   .then((response) => {
     if (response) {
       window.location.href = redirectUrl+"/home";
       instance.setActiveAccount(response.account);
     }
   })
   .catch(err => {
     console.error(err);
   });

 //call to msal login
 const handleLogin = () => {
   instance.loginRedirect(loginRequest).then((response) => {
   }).catch((e) => {
   });
   return true;
 };

  const signInButtonClickHandler = () => {
    console.log("signInButtonClickHandler");
   handleLogin();
 };

 const signOutButtonClickHandler = () => {
  handleSignOut();
}

  //call to msal logout
  const handleSignOut = () => {
    const account = instance.getActiveAccount();
    const logoutRequest = {
      account: account,
      postLogoutRedirectUri: instance.getConfiguration().auth.redirectUri,
    };
    instance.logoutRedirect(logoutRequest).then(()=>{
      navigate('/');
    });
  };

  useEffect(() => {
    if (userData === null) {
     requestProfileData();     
    }
    let page = location;
    if (!isAuthenticated) {
      navigate("/");
    }
    else {
      navigate(page);
    }
  }, [isAuthenticated]);
  
  const requestProfileData = () => {
    const request = { ...loginRequest, account: instance.getActiveAccount() }

    instance.acquireTokenSilent(request).then(tokenResponse => {
      sessionStorage.setItem('accessToken', tokenResponse.idToken);
      const sessionToken = generateRandomToken(15);
      sessionStorage.setItem('SessionToken', sessionToken);

      //call to graph api to fetch profile details
      callMsGraph(tokenResponse.accessToken).then((response) => {
          const requestOnboard = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('accessToken'),
            'SessionToken': sessionToken,
            'email': response.mail,
            'action': 'login',
          }, 
            body: JSON.stringify({ name: response.displayName, email:response.mail })
        };
          fetch(ApiConfig.baseUrl + ApiConfig.onboardUser + "?Subscription-Key=" + ApiConfig.apiM, requestOnboard)
          .then((res) => res.json())
          .then((userDataReponse) => {
              fetchData(userDataReponse.id, promptAPIservice, dispatch, userDataReponse.email);
              fetchSystemData(promptAPIservice, dispatch, userDataReponse.email);
              setChatHistory(chatHistoryService, userDataReponse.email, dispatch);
              dispatch(setUserProfileData(userDataReponse));
              dispatch(setIsUserOnboarded(true));                                        
              setIsUserOnboarded(true);
            })
          .catch((error) =>{
              //handleSignOut();
            })
      });
    }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return instance.acquireTokenRedirect(request)
      }
    });
  }

  const generateRandomToken = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters.charAt(randomIndex);
    }
    return token;
  };


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {isAuthenticated ? (
          <Route element={<Layout signOut={signOutButtonClickHandler} />}>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/finetune" element={<FinetunePage />} />
            <Route path="*" element={<FallbackPage />} />
          </Route>
          ) : (
            <Route path="/" element={<WelcomePage signIn={signInButtonClickHandler} />} />
        )}      
      </Routes>
    </ThemeProvider>
  );
}

export default App;
