import ApiConfig from "../config/ApiConfig.json";
import axios from 'axios';
import {
  createSlice
} from "@reduxjs/toolkit";

const initialState = {
  data: [],
  systemData: [],
  filteredData: [],
  chatIds: [],
};

export const dataSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      state.filteredData = action.payload;
    },
    setSystemData: (state, action) => {
      state.systemData = action.payload;
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    setChatId: (state, action) => {
      state.chatIds = action.payload;
    }
  }
})

export const {
  setData,
  setSystemData,
  setFilteredData,
  setChatId
} = dataSlice.actions;

export default dataSlice.reducer;

export const fetchData = async function (userId, promptAPIservice, dispatch, useremail) {
  try {
    const res = await promptAPIservice.promptListUser(userId, useremail);
    dispatch(setData(res.data));
  } catch (error) {
    console.error(error);
  }
}

export const fetchSystemData = async function (promptAPIservice, dispatch, useremail) {
  try {
    const res = await promptAPIservice.promptListSystem(useremail);
    dispatch(setSystemData(res.data));
  } catch (error) {
    console.error(error);
  }
}

export const setChatHistory = async function (chatHistoryService, useremail, dispatch) {
  try {
    const res = await chatHistoryService.chatHistory(useremail);
    dispatch(setChatId(res.data.filter(item => !item.isDeleted)));
  } catch (error) {
    console.error(error);
  }
}

class FineTuneApiService {
  fetchListFineTune = async (useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.listFineTune + "?email=" + useremail + "&updateDb=true" + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
      }
      const response = await axios.get(url, { headers });
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  callFileUpload = async (fileReq, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.filesUpload + "?email=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': useremail,
        'action': 'callFileUpload', 
      }
      const response = await axios.post(url, fileReq, { headers });

      if (!response.data.successful) {
        return response.error.error_message;
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  fileValidation = async (fileReq, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.validateFile + "?email=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
      }
      const response = await axios.post(url, fileReq, { headers });

      return response.data;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  fileRetrieval = async (fileId, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.fileRetrieval + fileId + "&email=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
      }
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  fileList  = async (useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.fileList + "?email=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
      }
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  createFineTune = async (fineTuneReq, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.fineTune + "?email=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': useremail,
        'action': 'createFineTune',
      }
      const response = await axios.post(url, fineTuneReq, { headers });
      return response.data;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  cancelFineTune = async (fineTuneId, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.cancelFineTune + fineTuneId + "&user=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': useremail,
        'action': 'cancelFineTune',
      }
      const response = await axios.get(url, { headers });
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  deleteFineTune = async (fineTuneId, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.deleteFineTine + fineTuneId + "&user=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': useremail,
        'action': 'deleteFineTune',
      }
      const response = await axios.get(url, { headers });
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  getFineTune = async (fineTuneId, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.getFineTune + fineTuneId + "&user=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
      }
      const response = await axios.get(url, { headers });
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  getFineTuneEvents = async (fineTuneId, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.fineTuningJobEvents + fineTuneId + "&user=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': useremail,
        'action': 'getFineTuneEvents',
      }
      const response = await axios.get(url, { headers });
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  getFileContent = async (fileId, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.fetchFileContent + fileId + "&user=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
      }
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (err) {
      return err;
    }
  };

}

class PromptAPIservice {
  promptListUser = async (userId, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.list + userId + "?Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),        
      }
      const response = await axios.get(url, { headers });
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  promptListSystem = async (useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.list + ApiConfig.system + "?Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
      }
      const response = await axios.get(url, { headers });
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  promptCreate = async (userId, data, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.create + userId + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': useremail,
        'action': 'promptCreate',
      }
      const response = await axios.post(url, data, { headers });
      fetchData();
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  promptUpdate = async (data, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.update + "?Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': useremail,
        'action': 'promptUpdate',
      }
      const response = await axios.put(url, data, { headers });
      fetchData();
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  }

  promptClone = async (promptId, userId, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.clone + promptId + "?userId=" + userId + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': useremail,
        'action': 'promptClone',
      }
      const response = await axios.get(url, { headers });
      fetchData();
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  }

  promptDelete = async (promptId, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.delete + promptId + "?Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': useremail,
        'action': 'promptDelete',
      }
      const response = await axios.delete(url, { headers });
      fetchData();
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  personaFavourite = async (data, useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.favourite + "?Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': useremail,
        'action': 'personaFavourite',
      }
      const response = await axios.put(url, data, { headers });
      fetchData();
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  }
}

class ChatHistoryService {
  chatHistory = async (useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.chatIds + "/" + useremail + "?Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),   
        'SessionToken': sessionStorage.getItem('SessionToken'),     
      }
      const response = await axios.get(url, { headers });
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  getChat = async (useremail, chatId) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.chatIds + "/" + useremail + "/" + chatId + "?Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),   
        'SessionToken': sessionStorage.getItem('SessionToken'),     
      }
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  setChatTitle = async (useremail, chatId, newTitle) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.chatIds + "/" + useremail + "/" + chatId + "?title=" + newTitle + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),  
        'SessionToken': sessionStorage.getItem('SessionToken'),      
      }
      const response = await axios.put(url, {}, { headers });
      return response.data;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  };

  deleteChat = async (useremail, chatId) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.chatIds + "/" + useremail + "/" + chatId + "/" + "true" + "?Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),   
        'SessionToken': sessionStorage.getItem('SessionToken'),     
      }
      const response = await axios.put(url, {}, { headers });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export {
  PromptAPIservice,
  FineTuneApiService,
  ChatHistoryService
}