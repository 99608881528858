import { createSlice } from "@reduxjs/toolkit";
import Constants from "../components/ChatBox/Constants";

const generateRandomChatId = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    token += characters.charAt(randomIndex);
  }
  return token;
};

localStorage.getItem("theme") ?localStorage.setItem("theme", localStorage.getItem("theme")):localStorage.setItem("theme","light");

const initialState = {
  mode: localStorage.getItem("theme"),
  sidebarOpen: true,
  selectedSets: [],
  condAccepted: false,
  filteredSets: [],
  messages: [
    {
      type: Constants.CSSClassNames.BotMessageClass,
      message: Constants.Messages.WelcomeMessage,
    },
  ],
  settings: {'model' : 'GPT35TDEPLOY20230717', 'temperature': 0.5, 'maxTokens': 20, 'frequencyPenalty' : 1, 'presencePenalty': 1, 'stopIndicator': '\\n', 'promptEndIndicator': '' },
  userProfileData: null,
  modelList:[],
  selectedChatId: generateRandomChatId(12),
  isUserOnboarded: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
      localStorage.setItem("theme",state.mode);
    },
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    toggleSet: (state, action) => {
      const itemID = action.payload.id
      const index = state.selectedSets.findIndex(box => box.id === itemID)
      if (index === -1) {
        if (index) {
          state.selectedSets.splice(index, 1)
        }
        state.selectedSets.push(action.payload)
      }
      else {
        state.selectedSets.splice(index, 1)
      }
    },
    unselectSetID: (state, action) => {
      const itemID = action.payload
      const index = state.selectedSets.findIndex(box => box.id === itemID)
      if (index !== -1) {
        state.selectedSets.splice(index, 1)
      }
    },
    clearSelectedSets: (state) => {
      state.selectedSets = []
    },
    setFunction: (state, action) => {
      state.function = action.payload;
      if (state.function !== 'code_conversion') {
        state.outputLang = state.inputLang;
      }
    },
    setPromptValues: (state, action) => {
      state.currentPrompt = action.payload
    },
    setCondAccepted: (state) => {
      state.condAccepted = !state.condAccepted;
    },
    setFilteredSets: (state,action) => {
      state.filteredSets = action.payload;
    },
    updateSelectedGoal: (state, action) => {
      const { setIndex, textIndex, newText } = action.payload;
      state.selectedSets[setIndex].promptText[textIndex] = newText;
    },
    updateSettings: (state, action) => {
      const { key, value } = action.payload;
      if (state.settings.hasOwnProperty(key)) {
        state.settings[key] = value;
      }
    },
    updateMessages: (state, action) => {
      const { type, message } = action.payload;
      const lastMessage = state.messages.length > 0 ? state.messages[state.messages.length - 1].message : null;
      if (lastMessage !== message) {
        state.messages.push({ type, message });
      }
    },    
    resetMessages: (state) => {
      state.messages = [
        {
          type: Constants.CSSClassNames.BotMessageClass,
          message: Constants.Messages.WelcomeMessage,
        },
      ]
    },
    setNewRandomChatId: (state) => {
      state.selectedChatId = generateRandomChatId(12);
    },
    setSelectedChatId: (state, action) => {
      state.selectedChatId = action.payload;
    },
    setUserProfileData: (state, action) => {
      state.userProfileData = action.payload;
    },
    setModelList: (state,action) => {
      state.modelList = action.payload;
    },
    setIsUserOnBoarded: (state,action) => {
      state.isUserOnboarded = action.payload;
    }
  }
})

export const { 
  setMode, 
  toggleSidebar, 
  toggleSet, 
  unselectSetID,
  setPromptValues,
  setCondAccepted,
  setFilteredSets,
  updateSelectedGoal,
  toggleFavorite, 
  clearSelectedSets,
  updateSettings,
  updateMessages,
  resetMessages,
  setNewRandomChatId,
  setUserProfileData,
  setIsUserOnBoarded,
  setChatId,
  setSelectedChatId,
  setModelList,
} = globalSlice.actions;

export default globalSlice.reducer;