import { Box } from "@mui/material";
import ChatBox from "../../components/ChatBox/ChatBox";

const Home = () => {

	return (
		<Box sx={{ position: 'relative', width: '100%' }}><ChatBox /></Box>
	)
}

export default Home;