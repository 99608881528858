import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useEffect, useState } from 'react';

const steps = ["Uploaded", "Validated", "Processed"];
const initialSteps = ["Uploading", "Validating", "Processing"];
const completedSteps = ["Uploaded", "Validated", "Processed"];

const FineTuneStepper = ({ step }) => {
  const [currentSteps, setCurrentSteps] = useState(initialSteps);

  useEffect(() => {
    setCurrentSteps((prevSteps) => prevSteps.map((_, index) => (index < step ? completedSteps[index] : initialSteps[index])));
  }, [step]);

  return (
    <>
      <Box sx={{ width: "100%", paddingTop: 1 }}>
        <Stepper activeStep={step} alternativeLabel sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: '#DA291C',
                },
                '& .MuiSvgIcon-root': {
                  height: '20px !important',
                  width: '20px !important',
                },
                '& .MuiStepLabel-label': {
                  fontSize: '12px',
                  marginTop: '4px'
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: '#000000'
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: '#000000',
                },
                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                  color: '#000000',
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: '#F0F0F0',
                },
              }} >
          {currentSteps.map((label, index) => (
            <Step key={label}>
              <StepLabel sx={{
                '@keyframes blink': {
                  '0%': { opacity: 1 },
                  '50%': { opacity: 0 },
                  '100%': { opacity: 1 },
                },
                animation: index === step ? 'blink 1s infinite' : 'none',
              }}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};

export default FineTuneStepper;
