import React, { useState , useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { visuallyHidden } from '@mui/utils';
import FileProgress from "./FileProgress";
import { PromptAPIservice, FineTuneApiService } from '../../services/PromptAPIservice';
import ConfirmationBox from '../DialogBox/ConfirmationBox.jsx';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import Tooltip from '@mui/material/Tooltip';
import { DeleteOutline, InfoOutlined } from '@mui/icons-material';
import { combineReducers } from '@reduxjs/toolkit';
import { Dna } from  'react-loader-spinner';
import { AlertContext } from '../../AlertContext';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

function stableSort(array, comparator, order) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  return order === 'asc' ? 
    stabilizedThis.map((el) => el[0]).reverse() : 
    stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'fineTunedModel',
    numeric: false,
    label: 'Model Name',
    width: '40%'
  },
  {
    id: 'createdTimestamp',
    numeric: true,
    label: 'Date Created',
    width: '40%'
  },
  {
    id: 'status',
    numeric: true,
    label: 'Status',
    width: '5%'
  },
  {
    id: 'icons',
    numeric: false,
    label: 'Events',
    width: '5%'
  },
  {
    id: 'delete',
    numeric: false,
    label: 'Abort',
    width: '5%'
  },
  {
    id: 'icons',
    numeric: false,
    label: 'Delete',
    width: '5%'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const mode = useSelector((state) => state.global.mode);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ backgroundColor: mode === 'dark' ? 'black' : '#f5f5f5' }}>
        {headCells.map((headCell) => (
          <TableCell
            width={headCell.width}
            key={headCell.id}
            align="left"
            padding= 'normal'
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontFamily: 'Inter',
              fontWeight: 'bold',
              fontSize: '12px',
            }}
          >
            {headCell.id === 'createdTimestamp' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                reverse={orderBy === headCell.id && order === 'desc'}
                sx={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: '12px' }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography variant="subtitle1" sx={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: '12px', }}>
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function BatchHistory() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('time');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const promptAPIservice = new PromptAPIservice();
  const fineTuneApiService = new FineTuneApiService();
  const [rows, setRows] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteFineTune, setDeleteFineTuneItem] = useState(null);
  const [openAbort, setOpenAbort] = useState(false);
  const [eventItem, setEventItem] = useState(null);
  const [openEvents, setOpenEvents] = useState(false);
  const [abortItem, setAbortItem] = useState(null);
  const userData = useSelector((state) => state.global.userProfileData);
  const [loading, setLoading] = useState(false);

  const { setAlert } = useContext(AlertContext);
  
  useEffect(() => {
    fetchFineTunes();
  }, []);

  const fetchFineTunes = async () => {
    setLoading(true);
    try {
      const res = await fineTuneApiService.fetchListFineTune(userData.email);
      setRows(res.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clickAbortButtonHandler = async function (fineTuneId, event){
     try{
      const res = await fineTuneApiService.cancelFineTune(fineTuneId, userData.email);
      console.info(res.data.status);
      if(res.data.status == 'cancelled') {
        setAlert({ open: true, severity: 'success', message: 'Finetune cancelled' });
        fetchFineTunes();    
      }      
    }
    catch(error){
      setAlert({ open: true, severity: 'error', message: error });
    }
    setOpenAbort(false);
  }

  const handleAbortClick = (item, event) => {
    event.stopPropagation();
    setOpenAbort(true);
    setAbortItem(item);
  }

  const handleDeleteClick = (item, event) => {
    event.stopPropagation();
    setOpenDelete(true);
    setDeleteFineTuneItem(item);
  }

  const handleEventInfoClicked = (item, event) => {
    event.stopPropagation();
    setOpenEvents(true);
    setEventItem(item);
  }

  const clickDeleteButtonHandler = async function (){
    try{
      const res = fineTuneApiService.deleteFineTune(deleteFineTune.fineTuneId, userData.email);
      if(res.successful){
        fetchFineTunes();
      }      
    }
    catch(error){
      console.error('Error:', error);
    }
    setOpenDelete(false);
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy), order).slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage,
      ),
    [rows, order, orderBy, page, rowsPerPage],
  );

  return (
    <Box sx={{ width: '100%', overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'column'}}>
        <div style={{ width: '730px', position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff',  display: 'inline-block' }}>
          <TableContainer sx={{overflowX: 'hidden'}}>
            <Table sx={{ minWidth: '730px' }} aria-labelledby="tableTitle" size="small">
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            </Table>
          </TableContainer>
        </div>
        <div style={{ width: '730px', maxHeight: '54vh', overflow: 'auto', display: 'inline-block' }}>
          <TableContainer sx={{overflowX: 'hidden'}}>
          {loading ? (<Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{marginLeft: '45%', marginTop: '5%'}}
            wrapperClass="dna-wrapper"
          />) : 
            (<Table sx={{ minWidth: '700px'}} aria-labelledby="tableTitle" size="small">
              <TableBody>
                {visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                    >
                    <TableCell
                      width="30%"
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ marginLeft: '10px', paddingLeft: '15px'}}
                    >
                      <Tooltip title={row.fineTunedModel}  placement="right" arrow arrowPlacement="right" enterDelay={500}>
                      <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '12px', maxWidth:'200px',
                         overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap' }}>
                        {row.fineTunedModel ? row.fineTunedModel :'Custom fineTune'+(index+1)}
                      </Typography>
                      </Tooltip>
                      </TableCell>
                      <TableCell width="50%" align="left">
                        <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '12px', }}>
                        {new Date(row.createdTimestamp).toLocaleString('en-US', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                          hour12: true
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell width="5%" align="left">
                        <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '12px', }}>
                        <Tooltip title={row.status.charAt(0).toUpperCase() + row.status.slice(1)}  placement="right" arrow arrowPlacement="right" enterDelay={500}>
                        
                        <IconButton>
                        {row.status == 'succeeded' ? <CheckCircleOutlineOutlinedIcon fontSize="small"/> : 
                            row.status == 'pending' ? <HourglassTopOutlinedIcon fontSize="small"/> :
                            row.status == 'cancelled' ? <CancelOutlinedIcon fontSize="small"/> :
                             
                              <ErrorOutlineOutlinedIcon fontSize="small" />}
                        </IconButton>
                        </Tooltip>
                        </Typography>
                      </TableCell>
                      <TableCell width="5%" align="left">
                        <IconButton className="iconButton" onClick={(event) => handleEventInfoClicked(row, event)}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell width="5%" align="right">
                        <IconButton className="iconButton" disabled={row.status != 'running'} onClick={(event) => handleAbortClick(row, event)}>
                        <CloseIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell width="5%" align="right">
                        <IconButton style={{ marginRight: '10px' }} className="iconButton" disabled={row.status != 'succeeded'} onClick={(event) => handleDeleteClick(row, event)}>
                          <DeleteOutline fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
                {deleteFineTune
                ? <ConfirmationBox open={openDelete} setOpen={setOpenDelete} item={deleteFineTune} handler={clickDeleteButtonHandler} handlerArgs={deleteFineTune.id} title='Delete Finetune' message= {
                  <span>
                    Are you sure you want to delete {' '}
                    <strong>{deleteFineTune.fineTunedModel ? deleteFineTune.fineTunedModel : deleteFineTune.id}</strong>?
                  </span>
                }/>
                : null
                }
                {abortItem
                ? <ConfirmationBox open={openAbort} setOpen={setOpenAbort} item={abortItem} handler={clickAbortButtonHandler} handlerArgs={abortItem.id} title='Abort Job' message= {
                  <span>
                    Are you sure you want to abort {' '}
                    <strong>{abortItem.fineTunedModel ? abortItem.fineTunedModel : abortItem.id}</strong>?
                  </span>
                }/>
                : null
                }

              {eventItem
                ? <FileProgress open={openEvents} setOpen={setOpenEvents} fineTuneId={eventItem.id} emailId={userData.email} status={eventItem.status} />
                : null
              }   
              </TableBody>
            </Table>
            )}
          </TableContainer>
        </div>
      <TablePagination
        rowsPerPageOptions={[5,10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
