import * as React from 'react';
import { useContext } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fonttype, fonttypeHeader, accordionHeader } from './SidebarStyles';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleFavorite, unselectFavorite, unselectSetID, toggleSet } from '../../state/index.jsx';
import { useTheme, Accordion, AccordionSummary, AccordionDetails, Typography,  List, ListItemButton, Box, IconButton } from '@mui/material';
import { PromptAPIservice, fetchData, fetchSystemData } from '../../services/PromptAPIservice';
import StarRateIcon from '@mui/icons-material/StarRate';
import { AlertContext } from '../../AlertContext.jsx';

export default function FavouritePersona() {
  // Styling
  const theme = useTheme();
  const fonttypeStyle = fonttype();
  const fonttypeHeaderStyle = fonttypeHeader();
  const accordionHeaderStyle = accordionHeader();
  const iconButtonStyles = {
    fontSize: '15px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  // Initializations
  const dispatch = useDispatch();
  const systemData = useSelector((state) => state.api.systemData);
  const userData = useSelector((state) => state.api.data);
  const promptAPIservice = new PromptAPIservice();
  const userProfileData = useSelector((state) => state.global.userProfileData);
  const user_id = userProfileData.id;
  const selectedItems = useSelector((state) => state.global.selectedSets);
  const data = systemData.concat(userData);
  const model = useSelector((state) => state.global.settings.model);
  const { setAlert } = useContext(AlertContext);

  // Handlers
  const handleFavoriteClick = async (item, event) => {
    event.stopPropagation();
    const data = {
      id : item.id,
      favourite : item.favourite ? false : true
    }
    try {
      const res = await promptAPIservice.personaFavourite(data, userProfileData.email);
      if(data.favourite == true) {
        setAlert({ open: true, severity: 'success', message: 'Added to Favourites' });
      }
      else {
        setAlert({ open: true, severity: 'success', message: 'Removed from Favourites' });
      }      
    }
    catch (error) {
      console.error('Persona favourite failed:', error);
      setAlert({ open: true, severity: 'error', message: 'Favourites update failed' });
    }
    fetchData(user_id, promptAPIservice, dispatch, userProfileData.email);
    fetchSystemData(promptAPIservice, dispatch, userProfileData.email);
  }
  const handleBoxClick = (item) => {
    if (model == 'GPT35TDEPLOY20230717' || model == 'gpt-4' || model == 'gpt-3.5-turbo' || model == 'gpt-4-1106-preview'){
      const isSelected = selectedItems.includes(item);
      if (isSelected) {
        dispatch(unselectSetID(item.id));
      } else {
        dispatch(toggleSet(item));
      }
    }
    else{
      setAlert({ open: true, severity: 'error', message: 'Persona can only be selected for chat completion models' });
    }
  }

  return (
    <div>
      <Accordion defaultExpanded={true} sx={{boxShadow: 'none'}}>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color: theme.palette.font.main}}  />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ ...(accordionHeaderStyle), backgroundColor: theme.palette.accordion.header}}
        >
          <Typography noWrap={true} sx={{...(fonttypeHeaderStyle), color: theme.palette.font.main}}>FAVOURITES</Typography> 
     
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor: theme.palette.accordion.content, padding: 0 }}>
          {data
            .filter(item => item.favourite )
            .map((item, index) => (
            <React.Fragment key={item.id}>
              <Box sx={{display:'flex', width:'100%', maxWidth: 200, justifyContent: 'flex-start', '&:hover .iconButton': {visibility: 'visible'}}}>
                <List disablePadding>
                  <ListItemButton onClick={() => handleBoxClick(item)} sx={{
                    whiteSpace: 'nowrap',
                    height: '35px',
                    width: '260px',
                    display: 'flex',  
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    backgroundColor: selectedItems.some(selectedItem => selectedItem.id === item.id)
                      ? theme.palette.action.selected
                      : 'transparent',
                    '&:hover': {
                      '& .hoverBox': {
                        maxWidth: '88%',
                      },
                    },
                    }}>
                    <Box className="hoverBox" sx={{ maxWidth: '100%', display: 'flex', gap: 0.2, justifyContent: 'flex-end', }}>
                      <Typography noWrap={true}
                        sx={{
                          ...(fonttypeStyle),
                          color: theme.palette.font.main,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {item.personaTitle}</Typography>
                    </Box>
                    <Box sx={{display:'flex', gap: 0.2, width: '100%', justifyContent: 'flex-end', 
                      '& .iconButton': {
                        visibility: 'hidden',
                      }, }}>
                      <IconButton className="iconButton" size="small" sx={{
                        ...iconButtonStyles,
                        }}
                        onClick={(event) => handleFavoriteClick(item, event)}>
                        <StarRateIcon fontSize="extra-small" />
                      </IconButton>
                    </Box>
                  </ListItemButton>    
                </List>
              </Box>
            </React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
