export const paper = () => ({
  marginTop: '5vh',
  marginLeft: '12%',
  width: '76%',  
  padding: '10px 20px 10px 20px', 
  position: 'relative',
});

export const radio = () => ({
  color: '#DA291C',
  '&.Mui-checked': {
    color: "#DA291C",
    '& .MuiSvgIcon-root': {
      fill: "#DA291C",
    },
  },
  transform: 'scale(0.7)',
  padding: 0,
  paddingLeft: '20px',
});

export const rowFiles = () => ({
  fontFamily: 'inter',
  fontSize: '15px',
  lineHeight: '30px',
  width: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  height: '30px',
  width: '100%',
  borderBottom: '1px solid grey',
});

export const fonttype = () => ({
  fontFamily: 'inter',
  fontSize: '14px',
  fontWeight: '300',
  lineHeight: '20px',
  letterSpacing: '0em',
  textAlign: 'left',
});

export const fonttypeHeader = () => ({
  fontFamily: 'inter',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '45px',
  letterSpacing: '0em',
  textAlign: 'left',
  marginLeft: '5px'
});

export const accordionHeader = () => ({
  flexDirection: 'row-reverse',
  minHeight: '35px',
  maxHeight: '35px',
  '&.Mui-expanded': {
    minHeight: '35px',
    maxHeight: '35px',
  },
  '.MuiAccordionSummary-content': {
    margin: '0px',
    padding: '0px',
    '&.Muiexpanded': {
      margin: '0px',
      padding: '0px',
    },
  },
});