export default class Constants {
  static Messages = {
    WaitingMessage: "GPT is thinking...",
    WaitingMessageWarning:
      "Please Wait!! GPT is thinking... Cannot send more messages!",
    WelcomeMessage: "Welcome to TechHive GenAI Playground",
  };
  static CSSClassNames = {
    UserMessageClass: "User-Message Message",
    BotMessageClass: "Bot-Message Message",
  };
  static Keys = {
    Enter: "Enter",
  };
  static PersonaKeys = {
    PersonaID: "PersonaID",
    DisplayName: "DisplayName",
    ConcatenateBotResponse: "ConcatenateBotResponse",
    DicussionInitiatedByBot: "DicussionInitiatedByBot",
    StartMessage: "StartMessage",
    ContextRules: "ContextRules",
    SystemPersona:"SystemPersona"
  };
  static CompletionDefaults = {
    Model: "text-davinci-003",
    MaxTokens: "2048",
    Temperature: "0.5",
    Stream: false,
    User: "1",
    FrequencyPenalty: "0.0",
    PresencePenalty: "0.0",
    Stop: "####",
    PromptEndIndicator:''
  };

  static ChatCompletionDefaults = {
    Model: "gpt-3.5-turbo",
    MaxTokens: "2048",
    Temperature: "0.5",
    Stream: false,
    User: "1",
    FrequencyPenalty: "0.0",
    PresencePenalty: "0.0",
    Stop: "####",
    PersonaID: "",
  };

  static restrictedModels = [
    "gpt-3.5-turbo", "text-davinci-003", "text-curie-001", "text-babbage-001",
    "text-ada-001", "text-davinci-002"
  ]

  static NoOfDaysAgeToShowFailedFineTunes = 5

  static one_day = 1000 * 3600 * 24
}
