import ApiConfig from "../config/ApiConfig.json";
import axios from "axios";
export default class TechHiveAPIService {

  getCompletion = async (message) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.completion + "?Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': message.user,
        'action': 'completion',
      };
      const response = await axios.post(url, message, { headers });
      return response.data.choices[0].text;
    } catch (error) {
      if (error.response) {
      } else if (error.request) {
      } else {
      }
    }
  };

  getChatCompletion = async (message) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.chatCompleteion + "?Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('accessToken'),
        'SessionToken': sessionStorage.getItem('SessionToken'),
        'email': message.user,
        'action': 'chatCompletion',
      }
      const response = await axios.post(url, message, { headers });
      return response.data.choices[0].message["content"];
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error.error;
      } else {
        return error;
      }
    }
  };

  fetchModels = async (useremail) => {
    try {
      const url = ApiConfig.baseUrl + ApiConfig.fetchModels + "?user=" + useremail + "&Subscription-Key=" + ApiConfig.apiM;
      const headers = {
        'Authorization': sessionStorage.getItem('accessToken'),
      }
      const response = await axios.get(url, { headers });
      if (!response.data.successful) {
        return response.data.error.message;
      }
      return response;
    } catch (error) {
      if (error.response) {
        return error;
      } else if (error.request) {
        return error;
      } else {
        return error;
      }
    }
  }
}