import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Outlet } from 'react-router-dom'
import AddPrompt from '../DialogBox/AddPrompt';
import { Box, Drawer, CssBaseline, Divider, Button, Fab, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar, setSelectedChatId, resetMessages, clearSelectedSets, updateMessages } from '../../state/index.jsx';
import PromptSets from './PromptSets';
import ChatHistory from './ChatHistory';
import styles from '../../theme.jsx';
import { drawer, drawerContent } from './SidebarStyles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Constants from '../ChatBox/Constants';

const drawerWidth = 260;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

export default function Sidebar() {
  // Styling
  const theme = useTheme();
  const drawerStyle = drawer(drawerWidth);
  const drawerContentStyle = drawerContent();

  // Initializations
  const dispatch = useDispatch();
  const openSidebar = useSelector((state) => state.global.sidebarOpen);
  const [openPrompts, setOpenPrompts] = useState(false);
  const userData = useSelector((state) => state.global.userProfileData);

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTabColor = (tabValue) => {
    return value === tabValue ? "#DA291C" : theme.palette.font.main;
  };

  const generateRandomChatId = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters.charAt(randomIndex);
    }
    return token;
  };

  const addMessage = (type, message) => {
    dispatch(updateMessages({ type, message }));
  };

  const newChat = () => {
    dispatch(setSelectedChatId(generateRandomChatId(12)));
    dispatch(resetMessages());
    dispatch(clearSelectedSets());
    addMessage(Constants.Messages.WelcomeMessage, "Explore GenAI directly or select a persona from the sidebar to provide prompts");
  };

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <Drawer
          sx={{ ...(drawerStyle) }}
          //disableScrollLock={true}
          PaperProps={{
            style: {
              backgroundColor: theme.palette.sidebar.default,
              border: 'none',
            }
          }}
          variant="persistent"
          anchor="left"
          open={openSidebar}
        >
          <Box sx={{ ...(drawerContentStyle) }}>

            <Box sx={{flex: '1 1 auto'}}>
            <TabContext value={value}>
              <Box sx={{ borderColor: 'divider' }}>
                <TabList onChange={handleChange} TabIndicatorProps={{style: {background: "#DA291C", height: '2px'}}}>
                  <Tab label={<Typography sx={{ ...styles.typography, fontSize: '14.5px', fontWeight: '500', color: getTabColor("1"), paddingTop: 0.75 }}>Personas</Typography>} sx={{ textTransform: "none", width:'100px' }} value="1" />
                  <Tab label={<Typography sx={{ ...styles.typography, fontSize: '14.5px', fontWeight: '500', color: getTabColor("2"), paddingTop: 0.75 }}>Chat History</Typography>} sx={{ textTransform: "none", width:'120px' }} value="2" />
                </TabList>
                <Divider></Divider>
              </Box>
              <TabPanel sx={{ padding: 0 }} value="1">{userData && <PromptSets />}</TabPanel>
              <TabPanel sx={{ padding: 0 }} value="2"><ChatHistory /></TabPanel>
            </TabContext>
            </Box>

            <Box sx={{ p: 1.5, flex: '0 0 auto', zIndex: 2, width: '100%', background: 'transparent', pointerEvents: 'none', margin: 0 }}>
              {value == 1 ? (<Button variant="containedBitwiseButton" onClick={() => setOpenPrompts(true)} disableElevation sx={{ left: '48px' }}>Add New Persona</Button>) : (
                <Button variant="containedBitwiseButton" onClick={() => newChat()} disableElevation sx={{ left: '48px' }}>New Chat<EditNoteIcon sx={{ marginLeft: 2 }} /></Button>)}
            </Box>

            {userData && <AddPrompt title={'Add Custom Persona'} method={'Add'} open={openPrompts} setOpen={setOpenPrompts} />}
          </Box>

        </Drawer>

        <Main open={openSidebar}>
          <Fab sx={{ left: '-1.3vw', top: '7vh' }} size="small" color="primary" onClick={() => dispatch(toggleSidebar())} aria-label="add">
            {openSidebar ? (
              <Box sx={{ display: 'flex' }}>
                <ChevronLeftIcon />
              </Box>
            ) : (
                <Box sx={{ paddingLeft: '16px', display: 'flex' }}>
                  <ChevronRightIcon />
                </Box>
              )}
          </Fab>
          <Outlet />
        </Main>
      </Box>
    </div>
  );
}
