import React from 'react';
import { useTheme, List, ListItemButton, Box, IconButton } from '@mui/material';
import { fonttype } from './SidebarStyles';
import EditIcon from '@mui/icons-material/Edit';
import { ChatHistoryService } from "../../services/PromptAPIservice";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useContext } from 'react';
import { AlertContext } from '../../AlertContext.jsx';
import { setSelectedChatId, clearSelectedSets, updateMessages } from '../../state/index';
import { setChatHistory } from '../../services/PromptAPIservice';
import { EditText } from 'react-edit-text';
import { createGlobalStyle } from 'styled-components';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ConfirmationBox from '../DialogBox/ConfirmationBox.jsx';
import { resetMessages, setNewRandomChatId } from '../../state';
import Constants from '../ChatBox/Constants';

const ChatHistory = () => {
  const settings = useSelector((state) => state.global.settings);
  const fonttypeStyle = fonttype();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setAlert } = useContext(AlertContext);
  const chatIds = useSelector((state) => state.api.chatIds.filter(chatId => chatId.model === settings.model));
  const selectedChatId = useSelector((state) => state.global.selectedChatId);
  const useremail = useSelector((state) => state.global.userProfileData.email);
  const chatHistoryService = new ChatHistoryService();
  const [initialChatTitles, setInitialChatTitles] = useState(chatIds.map((chatId) => chatId.title));
  const [editModes, setEditModes] = useState(Array(chatIds.length).fill(true));
  const [chatTitles, setChatTitles] = useState(initialChatTitles);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteChat, setDeleteChat] = useState(null);

  const textBox = () => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%'
  });

  const toggleEditMode = (index) => {
    setEditModes((prevEditModes) => {
      const newEditModes = [...prevEditModes];
      newEditModes[index] = !newEditModes[index];
      return newEditModes;
    });
  };


  useEffect(() => {
    setChatTitles(chatIds.map((chatId) => chatId.title));
    setInitialChatTitles(chatIds.map((chatId) => chatId.title));
    setEditModes((prevEditModes) => [...prevEditModes.slice(0, chatIds.length), true]);
  }, [chatIds.length]);

  const updateTitle = (event, index) => {
    const newChatTitles = [...chatTitles];
    newChatTitles[index] = event.target.value;
    setChatTitles(newChatTitles);
  };

  const handleSave = ({ value, chatId, index }) => {
    editChatTitle(chatId, value, index);
  };

  const textBoxStyle = textBox();

  const handleDeleteClick = (item, event) => {
    event.stopPropagation();
    setOpenDelete(true);
    setDeleteChat(item);
  }

  const addMessage = (type, message) => {
    dispatch(updateMessages({ type, message }));
};

  const clickDeleteButtonHandler = async function (item) {    
    try {
      const res = await chatHistoryService.deleteChat(useremail, item.chatId);
      if (res.isDeleted) {
        setAlert({ open: true, severity: 'success', message: 'Deleted chathistory for ' + item.title });
        dispatch(resetMessages());
        dispatch(setNewRandomChatId());    
        dispatch(clearSelectedSets());
        setChatHistory(chatHistoryService, useremail, dispatch);
        addMessage(Constants.Messages.WelcomeMessage, "Explore GenAI directly or select a persona from the sidebar to provide prompts");
      }
    }
    catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Internal Server Error. Please try again later.' });
      console.log("error");
    }
    setOpenDelete(false);
  }

  const editChatTitle = async function (chatId, newTitle, index) {
    const newChatTitles = [...chatTitles];
    try {
      if (newTitle === '') {
        newChatTitles[index] = initialChatTitles[index];
        throw new Error('Title cannot be set blank');
      }
      else if (initialChatTitles.includes(newTitle)) {
        newChatTitles[index] = initialChatTitles[index];
        throw new Error('Title already exists, choose a different one');
      }
      else {
        const res = await chatHistoryService.setChatTitle(useremail, chatId, newTitle);
        if (res.title === newTitle) {
          setAlert({ open: true, severity: 'success', message: 'Renamed title to ' + newTitle });
          const newChatTitles = [...chatTitles];
          newChatTitles[index] = newTitle;
          setInitialChatTitles(newChatTitles);
        }
        else {
          newChatTitles[index] = initialChatTitles[index];
          throw new Error('Title not updated');
        }
      }
    }
    catch (error) {
      setAlert({ open: true, severity: 'error', message: error.message });
    }
    finally {
      setChatTitles(newChatTitles);
      setChatHistory(chatHistoryService, useremail, dispatch);
    }
  };

  const GlobalStyle = createGlobalStyle`.editinput {
    background-color: ${props => props.bgColor};
  }
`;

  const iconButtonStyles = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  return (
    <Box sx={{ flexGrow: 1, overflowX: 'hidden', overflowY: 'auto', height: 'calc(95vh - 120px)' }}>
      {chatIds.map((item, index) => (
        <Box sx={{ display: 'flex', width: '100%', maxWidth: 200, justifyContent: 'flex-start', '&:hover .iconButton': { visibility: 'visible' } }}>
          <List disablePadding sx={{
            '& .MuiListItemButton-root': {
              '&.Mui-focusVisible': {
                backgroundColor: selectedChatId === item.chatId
                ? theme.palette.action.selected
                : 'transparent',
              }
            },
          }}>
            <ListItemButton
              onClick={() => dispatch(setSelectedChatId(item.chatId))}
              sx={{
                whiteSpace: 'nowrap',
                height: '35px',
                width: '260px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: selectedChatId === item.chatId
                  ? theme.palette.action.selected
                  : 'transparent',
                '& .hoverBox': {
                  maxWidth: editModes[index] && selectedChatId === item.chatId ? '88%' : 'initial',
                },
              }}>

              <React.Fragment key={item}>
                <GlobalStyle bgColor={theme.palette.square.background} />

                <Box className="hoverBox" sx={{ ...(textBoxStyle), display: 'flex', justifyContent: 'space-between', width: editModes[index] && selectedChatId === item.chatId ? '175px' : 'auto' }}>
                  <EditText
                    key={`${index}-${item.chatId}`}
                    name={`textbox-${index}`}
                    value={chatTitles[index]}
                    showEditButton
                    readonly={selectedChatId !== item.chatId}
                    editButtonContent={
                      <IconButton onClick={() => toggleEditMode(index)} className="iconButton" size="small">
                        <EditIcon fontSize="extra-small" />
                      </IconButton>
                    }
                    editButtonProps={{
                      style: {
                        padding: 0,
                        margin: 0,
                        border: 'none',
                        paddingBlock: 0,
                        backgroundColor: 'transparent',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '2.2rem',
                        zIndex: 200,
                      }
                    }}
                    onBlur={() => toggleEditMode(index)}
                    onSave={() => handleSave({ value: chatTitles[index], chatId: item.chatId, index })}
                    onChange={(event) => updateTitle(event, index)}
                    inputClassName='editinput'
                    style={{
                      ...(fonttypeStyle),
                      color: theme.palette.font.main,
                      display: 'flex',
                      margin: '0',
                      paddingLeft: '0px',
                      alignItems: 'center',
                      width: '228px',
                      border: 'none',
                    }}
                  />
                </Box>
              </React.Fragment>
              {editModes[index] && selectedChatId === item.chatId && <IconButton className="iconButton" size="small" onClick={(event) => handleDeleteClick(item, event)} sx={{
                ...iconButtonStyles,
                right: '0.5rem',
              }}>
                <DeleteOutlineIcon fontSize="extra-small"> </DeleteOutlineIcon>
              </IconButton>}
            </ListItemButton>
          </List>
        </Box>
      ))}
      {deleteChat
        ? <ConfirmationBox open={openDelete} setOpen={setOpenDelete} item={deleteChat} handler={clickDeleteButtonHandler} handlerArgs={deleteChat} title='Delete Chat session?' message={
          <span>
            Are you sure you want to delete {' '}
            <strong>{deleteChat.title}</strong>?
          </span>
        } />
        : null
      }
    </Box>
  )
}

export default ChatHistory
