import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Paper, Typography, Divider, Tooltip, TextField, InputLabel, Button, IconButton, FormGroup, Grid } from '@mui/material';
import { paper, fonttype } from './FineTuneStyles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import UploadIcon from '@mui/icons-material/Upload';
import Stack from '@mui/material/Stack';
import { AlertContext } from '../../AlertContext';
import { useContext } from 'react';
import { Slider, Select, MenuItem, CircularProgress } from "@mui/material";
import HistoryIcon from '@mui/icons-material/History';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FineTuneHistory from './FineTuneHistory';
import { FineTuneApiService } from '../../services/PromptAPIservice';
import { useState, useEffect, useRef } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import FileList from './FileList';
import FineTuneStepper from './FineTuneStepper';

export default function FineTune() {

    const theme = useTheme();
    const paperStyles = paper();
    const fonttypeStyle = fonttype();
    const [trainFile, setTrainFiles] = useState();
    const [trainFileName, setTrainFileName] = useState();
    const [trainFileId, setTrainFileId] = useState();
    const [testFile, setTestFiles] = useState();
    const [testFileName, setTestFileName] = useState();
    const [testFileId, setTestFileId] = useState();
    const [epoch, setEpoch] = useState(1);
    const [suffix, setSuffix] = useState('');
    const [model, setModel] = useState('');
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);
    const fineTuneApiService = new FineTuneApiService();
    const [isWaiting, setIsWaiting] = useState(false);
    const extension = ".jsonl";
    const [isLoading, setIsLoading] = useState(false);
    const userData = useSelector((state) => state.global.userProfileData);
    const [isTrainUploaded, setIsTrainUploaded] = useState(false);
    const [isTestUploaded, setIsTestUploaded] = useState(false);
    const [isSuffixEntered, setIsSuffixEntered] = useState(false);
    const [trainTooltip, setTrainTooltip] = useState();
    const [testTooltip, setTestTooltip] = useState();
    const [trainEstimation, setTrainEstimation] = useState();
    const [selectedPrevFile, setSelectedPrevFile] = useState(null);
    const [uploadType, setUploadType] = useState();
    const [trainingTokensVar, setTrainingTokens] = useState();
    const [step, setStep] = useState(0);
    const [isInProgressTrain, setIsInProgressTrain] = useState(false);
    const [isInProgressTest, setIsInProgressTest] = useState(false);

    const [openList, setOpenList] = useState(false);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 250,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const handleDataFromChild = (data) => {
        setSelectedPrevFile(data);
        if (data.uploadType == "trainFile") {
            setTrainFileId(data.fileId);
            setTrainFileName(data.fileName);
            setIsTrainUploaded(true);
            setTrainingTokens(data.trainingTokens);
            const tooltip = {
                "fileName": data.fileName,
                "baseModel": data.baseModel,
                "dataLength": data.dataLength,
                "numberMessages": data.numberMessages,
                "trainingTokens": data.trainingTokens,
                "conversationMinLength": data.conversationMinLength,
                "conversationMaxLength": data.conversationMaxLength,
                "conversationMeanLength": data.conversationMeanLength,
                "conversationMedianLength": data.conversationMedianLength,
                "assistantMinLength": data.assistantMinLength,
                "assistantMaxLength": data.assistantMaxLength,
                "assistantMeanLength": data.assistantMeanLength,
                "assistantMedianLength": data.assistantMedianLength,
                "examplesExceedingLimits": data.examplesExceedingLimits
            };
            if (model == 'gpt-3.5-turbo') {
                setTrainEstimation("$" + data.trainingTokens * epoch * 0.0080);
                console.log(data.trainingTokens * epoch * 0.0080);
            }
            else if (model == 'davinci-002') {
                setTrainEstimation("$" + data.trainingTokens * epoch * 0.0060);
                console.log(data.trainingTokens * epoch * 0.0060);
            }
            else {
                setTrainEstimation("$" + data.trainingTokens * epoch * 0.0004);
                console.log(data.trainingTokens * epoch * 0.0004);
            }
            setTrainTooltip(tooltip);
        }
        else {
            setTestFileId(data.fileId);
            setTestFileName(data.fileName);
            setIsTestUploaded(true);
            const tooltip = {
                "fileName": data.fileName,
                "baseModel": data.baseModel,
                "dataLength": data.dataLength,
                "numberMessages": data.numberMessages,
                "trainingTokens": data.trainingTokens,
                "conversationMinLength": data.conversationMinLength,
                "conversationMaxLength": data.conversationMaxLength,
                "conversationMeanLength": data.conversationMeanLength,
                "conversationMedianLength": data.conversationMedianLength,
                "assistantMinLength": data.assistantMinLength,
                "assistantMaxLength": data.assistantMaxLength,
                "assistantMeanLength": data.assistantMeanLength,
                "assistantMedianLength": data.assistantMedianLength,
                "examplesExceedingLimits": data.examplesExceedingLimits
            };
            setTestTooltip(tooltip);
        }
    };

    const handleRefreshHistory = () => {
        setIsHistoryOpen(false);
        setTimeout(() => { setIsHistoryOpen(true) }, 1);
    };

    useEffect(() => {
        if (!isHistoryOpen) {
            setEpoch(1);
            setModel('');
            setTrainFileId('');
            setTestFileId('');
            setTestFileName('');
            setTestFiles('');
            setTrainFiles('');
            setTrainFileName('');
            setIsTestUploaded(false);
            setIsTrainUploaded(false);
            setTrainTooltip('');
            setTestTooltip('');
            setTrainEstimation('');
        }
    }, [isHistoryOpen]);

    const handleModelChange = (event) => {
        setModel(event.target.value);
    };

    const handleSuffixChange = (event) => {
        setSuffix(event.target.value);
        if (event.target.value.length > 0) {
            setIsSuffixEntered(true);
        }
        else {
            setIsSuffixEntered(false);
        }
    };

    const handleEpochChange = (event, newValue) => {
        if (event.type == 'change') {
            if (/^([1-9]|1[0-9]|20)?$/.test(event.target.value)) {
                setEpoch(event.target.value);
                if (model == 'gpt-3.5-turbo') {
                    setTrainEstimation("$" + trainingTokensVar * event.target.value * 0.0080);
                }
                else if (model == 'davinci-002') {
                    setTrainEstimation("$" + trainingTokensVar * event.target.value * 0.0060);
                }
                else {
                    setTrainEstimation("$" + trainingTokensVar * event.target.value * 0.0004);
                }
            }
        }
        else {
            setEpoch(newValue);
            if (model == 'gpt-3.5-turbo') {
                setTrainEstimation("$" + trainingTokensVar * newValue * 0.0080);
            }
            else if (model == 'davinci-002') {
                setTrainEstimation("$" + trainingTokensVar * newValue * 0.0060);
            }
            else {
                setTrainEstimation("$" + trainingTokensVar * newValue * 0.0004);
            }
        }
    };

    const openFileListDialog = (uploadType) => {
        setOpenList(true);
        setUploadType(uploadType);
    };

    const { setAlert } = useContext(AlertContext);

    const handleTrainFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setTrainFileName('');
            setTrainFiles(file);
            validateFile(file, 'trainFile');
        }
    };

    const handletestFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setTestFileName('');
            setTestFiles(file);
            validateFile(file, 'testFile');
        }
    };

    const validateFile = async (file, uploadType) => {
        if (uploadType == 'trainFile') {
            setIsInProgressTrain(true);
        }
        else {
            setIsInProgressTest(true);
        }        
        setStep(1);
        setIsWaiting(true);
        let fileUploadRequest = {
            fileContent: "",
            fileName: "",
            baseModel: model
        };
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
            var fileContent = reader.result;
            fileUploadRequest.fileName = file.name;
            fileUploadRequest.fileContent = fileContent;
            fileUploadRequest.user = userData.email;
            const data = await fineTuneApiService.fileValidation(fileUploadRequest, userData.email);
            if (data.successful) {
                setStep(2);
                if (uploadType == 'trainFile') {
                    setTrainTooltip(data);
                    setTrainingTokens(data.trainingTokens);
                    if (model == 'gpt-3.5-turbo') {
                        setTrainEstimation("$" + data.trainingTokens * epoch * 0.0080);
                    }
                    else if (model == 'davinci-002') {
                        setTrainEstimation("$" + data.trainingTokens * epoch * 0.0060);
                    }
                    else {
                        setTrainEstimation("$" + data.trainingTokens * epoch * 0.0004);
                    }
                    uploadFile(file, uploadType, data);
                }
                else {
                    setTestTooltip(data);
                    uploadFile(file, uploadType, data.trainingTokens);
                }
            }
            else if (data.error) {
                setAlert({ open: true, severity: 'error', message: data.error.error_message });
            }
            else {
                setAlert({ open: true, severity: 'error', message: data.formatError.error_message });
            }
        }
        setIsWaiting(false);
    };

    const uploadFile = async (file, uploadType, data) => {
        setIsWaiting(true);
        let fileUploadRequest = {
            fileContent: "",
            fileName: "",
            baseModel: model,
            user: userData.email,
            uploadType: uploadType,
            fileValidationDetails: {
                dataLength: data.dataLength,
                numberMessages: data.numberMessages,
                trainingTokens: data.trainingTokens,
                conversationMinLength: data.conversationMinLength,
                conversationMaxLength: data.conversationMaxLength,
                conversationMeanLength: data.conversationMeanLength,
                conversationMedianLength: data.conversationMedianLength,
                assistantMinLength: data.assistantMinLength,
                assistantMaxLength: data.assistantMaxLength,
                assistantMeanLength: data.assistantMeanLength,
                assistantMedianLength: data.assistantMedianLength,
                examplesExceedingLimits: data.examplesExceedingLimits
            }
        };

        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
            var fileContent = reader.result;
            fileUploadRequest.fileName = file.name;
            fileUploadRequest.fileContent = fileContent;
            fileUploadRequest.user = userData.email;
            const data = await fineTuneApiService.callFileUpload(fileUploadRequest, userData.email);

            if (data && data.successful) {
                let retrievalAttempts = 0;

                const retryFileRetrieval = async () => {
                    retrievalAttempts++;

                    const retrievalData = await fineTuneApiService.fileRetrieval(data.id, userData.email);

                    if (retrievalData.status === 'processed') {
                        if (uploadType === 'trainFile') {
                            setTrainFileId(data.id);
                            setIsTrainUploaded(true);
                            setStep(3);
                            setIsInProgressTrain(false);
                        } else {
                            setTestFileId(data.id);
                            setIsTestUploaded(true);
                            setStep(3);
                            setIsInProgressTest(false);
                        }
                    } else if (retrievalAttempts < 5) {
                        setTimeout(retryFileRetrieval, 1000);
                    } else {
                        if (uploadType === 'trainFile') {
                            setIsInProgressTrain(false);
                        }
                        else {
                            setIsInProgressTest(false);
                        }                      
                        setAlert({ open: true, severity: 'info', message: "File uploaded and validated successfully, yet to be processed please select from the previous file list to proceed with finetuning" });
                    }
                };
                await retryFileRetrieval();
            } else {
                setIsWaiting(false);
            }
        };
    };


    const handleSubmit = async () => {
        setIsLoading(true);

        let trainFineTuneRequest;

        trainFineTuneRequest = {
            "trainingFile": trainFileId,
            "validationFile": testFileId,
            "nEpochs": epoch == 0 ? 'auto' : epoch,
            "suffix": suffix,
            "model": model,
            "user": userData.email,
        }

        const data = await fineTuneApiService.createFineTune(trainFineTuneRequest, userData.email);

        if (!data.successful) {
            setAlert({ open: true, severity: 'error', message: data.error.error_message });
        }
        else {
            setAlert({ open: true, severity: 'success', message: 'Finetune job creating successful' });
            setTrainFileId('');
            setTestFileId('');
            setTestFiles('');
            setTrainFiles('');
            setIsTestUploaded(false);
            setIsTrainUploaded(false);
            setSuffix('');
            const fileInput = document.getElementById('ctrl');
            if (fileInput) {
                fileInput.value = '';
            }
            const fileInput1 = document.getElementById('ctrlctrl');
            if (fileInput1) {
                fileInput1.value = '';
            }
        }
        setIsLoading(false);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ ...paperStyles, minHeight: '450px', maxHeight: '80vh', }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isHistoryOpen ? (<Typography sx={{ fontFamily: 'poppins', paddingBottom: 1, paddingLeft: 1, fontSize: 14 }}>Fine tune History</Typography>) : (<Typography sx={{ fontFamily: 'poppins', paddingBottom: 1, paddingLeft: 1, fontSize: 14 }}>Build your own custom model</Typography>)}

                    <Box sx={{ marginLeft: 'auto', marginRight: '20px' }}>
                        {isHistoryOpen ?
                            <IconButton className="iconButton" sx={{ marginTop: '-5px' }}>
                                <RefreshIcon fontSize="small" onClick={handleRefreshHistory} />
                            </IconButton> : <></>}
                        <IconButton className="iconButton" sx={{ marginTop: '-5px' }} onClick={() => setIsHistoryOpen(!isHistoryOpen)}>
                            {!isHistoryOpen ? <HistoryIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
                        </IconButton>
                    </Box>
                </Box>

                <Divider sx={{ marginBottom: '15px' }}></Divider>

                {isHistoryOpen ? (
                    <FineTuneHistory />
                ) : (
                        <>
                            <Box sx={{ maxHeight: '330px', overflow: 'auto' }}>
                                <Box sx={{ marginLeft: '50px' }}>
                                    <FormControl fullWidth sx={{
                                        m: 1, width: '35vw', marginTop: '10px', '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: theme.palette.textfield.focused,
                                            }
                                        },
                                        '& .MuiInputLabel-outlined.Mui-focused': {
                                            color: theme.palette.textfield.focused,
                                        }
                                    }} size="small">
                                        <InputLabel id="demo-simple-select-label">Select Base Model</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={model}
                                            // disabled={!isAdavcedControlEnabled}
                                            label="Select Base Model"
                                            onChange={handleModelChange}
                                        >
                                            <MenuItem value={'gpt-3.5-turbo'}>gpt-3.5-turbo</MenuItem>
                                            <MenuItem value={'davinci-002'}>davinci-002</MenuItem>
                                            <MenuItem value={'babbage-002'}>babbage-002</MenuItem>
                                        </Select>

                                        <HtmlTooltip placement="right" PopperProps={{
                                            modifiers: [
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: [100, 0],
                                                    },
                                                },
                                            ],
                                        }} title={
                                            <React.Fragment>
                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    <em><b>For base model 'gpt-3.5-turbo' please upload dataset in the following format:</b></em>
                                                </Typography>
                                                {'{"messages": [{"role": "system", "content": "<Global Instructions>"}, {"role": "user", "content": "<prompt text>"}, {"role": "assistant", "content": "<ideal generated text>"}]}'}

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    <em><b>For base models 'babbage-002' and 'davinci-002' please upload dataset in following format:</b></em>
                                                </Typography>

                                                {'{"prompt": "<prompt text>", "completion": "<ideal generated text>"}'}
                                                <br></br>
                                                <u><a target="_blank" href="https://bitwiseglobal0.sharepoint.com/sites/TechHive/Shared%20Documents/Playground-Guides/TechHive%20Playground%20V0.2%20User%20Guide.pdf">{'Read documentation for more details!'}</a></u>
                                            </React.Fragment>
                                        }
                                        >
                                            <IconButton
                                                className="iconButton"
                                                sx={{
                                                    fontSize: '16px',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '105%',
                                                    transform: 'translateY(-50%)',
                                                }}
                                                size="small"
                                            >
                                                <InfoIcon fontSize="extra-small" />
                                            </IconButton>
                                        </HtmlTooltip>
                                    </FormControl>

                                    <FormControl fullWidth sx={{
                                        m: 1, width: '35vw',
                                        backgroundColor: 'transparent',
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: theme.palette.textfield.focused,
                                            }
                                        },
                                        '& .MuiInputLabel-outlined.Mui-focused': {
                                            color: theme.palette.textfield.focused,
                                        }
                                    }} size="small">
                                        {trainFileName
                                            ? <InputLabel id="demo-select-small-label" shrink={false}>{trainFileName}</InputLabel>
                                            : trainFile
                                                ? <InputLabel id="demo-select-small-label" shrink={false}>{trainFile.name}</InputLabel>
                                                : <InputLabel id="demo-select-small-label" shrink={false}>Upload JSONL file with train data</InputLabel>
                                        }
                                        <TextField disabled labelId="demo-select-small-label" id="demo-select-small" size="small" InputProps={{
                                            endAdornment: (
                                                <Stack direction="row" alignItems="center">
                                                    <IconButton disabled={!model} component="label">
                                                        <UploadIcon fontSize='small' />
                                                        <input type="file" accept={extension} onChange={handleTrainFileChange} style={{ display: 'none' }} id="ctrl" />
                                                    </IconButton>
                                                    <IconButton disabled={!model} component="label">
                                                        <HistoryIcon fontSize='small' onClick={(event) => openFileListDialog("trainFile")} />
                                                    </IconButton>
                                                </Stack>
                                            ),
                                        }} />


                                        <HtmlTooltip open={isInProgressTrain ? true : undefined} placement="right" PopperProps={{
                                            modifiers: [
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: isInProgressTrain ? [12, 0] : [100, 0],
                                                    },
                                                },
                                            ],
                                        }} title={
                                            <>
                                                {isInProgressTrain ? (<FineTuneStepper step={step} />) : (
                                                    <>
                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Filename:&nbsp;
                                                    <em><b>{trainTooltip ?.fileName}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            DataLength:&nbsp;
                                                    <em><b>{trainTooltip ?.dataLength}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Number of Messages:&nbsp;
                                                    <em><b>{trainTooltip ?.numberMessages}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Training Tokens:&nbsp;
                                                    <em><b>{trainTooltip ?.trainingTokens}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Conversation MinLength:&nbsp;
                                                    <em><b>{trainTooltip ?.conversationMinLength}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Conversation MaxLength:&nbsp;
                                                    <em><b>{trainTooltip ?.conversationMaxLength}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Conversation MeanLength:&nbsp;
                                                    <em><b>{trainTooltip ?.conversationMeanLength}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            conversationMedianLength:&nbsp;
                                                    <em><b>{trainTooltip ?.conversationMedianLength}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Assistant MinLength:&nbsp;
                                                    <em><b>{trainTooltip ?.assistantMinLength}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Assistant MaxLength:&nbsp;
                                                    <em><b>{trainTooltip ?.assistantMaxLength}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Assistant MeanLength:&nbsp;
                                                    <em><b>{trainTooltip ?.assistantMeanLength}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Assistant MedianLength:&nbsp;
                                                    <em><b>{trainTooltip ?.assistantMedianLength}</b></em>
                                                        </Typography>

                                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                            Examples exceeding Limits:&nbsp;
                                                    <em><b>{trainTooltip ?.examplesExceedingLimits}</b></em>
                                                        </Typography>
                                                    </>)}
                                            </>
                                        }
                                        >
                                            <IconButton disabled={!trainTooltip} className="iconButton" sx={{
                                                fontSize: '16px',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '105%',
                                                transform: 'translateY(-50%)',
                                            }} size="small">
                                                <InfoIcon fontSize="extra-small" />
                                            </IconButton>
                                        </HtmlTooltip>


                                        {openList
                                            ? <FileList open={openList} setOpen={setOpenList} email={userData.email} baseModel={model} uploadType={uploadType} sendDataToParent={handleDataFromChild} />
                                            : null
                                        }
                                    </FormControl>

                                    <FormControl fullWidth sx={{
                                        m: 1, width: '35vw',
                                        backgroundColor: 'transparent',
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: theme.palette.textfield.focused,
                                            }
                                        },
                                        '& .MuiInputLabel-outlined.Mui-focused': {
                                            color: theme.palette.textfield.focused,
                                        }
                                    }} size="small">

                                        {testFileName
                                            ? <InputLabel id="demo-select-small-label" shrink={false}>{testFileName}</InputLabel>
                                            : testFile
                                                ? <InputLabel id="demo-select-small-label" shrink={false}>{testFile.name}</InputLabel>
                                                : <InputLabel id="demo-select-small-label" shrink={false}>Upload JSONL file with test data</InputLabel>
                                        }
                                        <TextField disabled labelId="demo-select-small-label" id="demo-select-small" size="small" InputProps={{
                                            endAdornment: (

                                                <Stack direction="row" alignItems="center">
                                                    <IconButton disabled={!model} component="label">
                                                        <UploadIcon fontSize='small' />
                                                        <input type="file" accept={extension} onChange={handletestFileChange} style={{ display: 'none' }} id="ctrlctrl" />
                                                    </IconButton>
                                                    <IconButton disabled={!model} component="label">
                                                        <HistoryIcon fontSize='small' onClick={(event) => openFileListDialog("testFile")} />
                                                    </IconButton>
                                                </Stack>
                                            ),
                                        }} />

                                        <HtmlTooltip open={isInProgressTest ? true : undefined} placement="right" PopperProps={{
                                            modifiers: [
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: isInProgressTest ? [12, 0] : [100, 0],
                                                    },
                                                },
                                            ],
                                        }} title={
                                            <>
                                                {isInProgressTest ? (<FineTuneStepper step={step} />) : (
                                            <>
                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Filename:&nbsp;
                                                    <em><b>{testTooltip ?.fileName}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    DataLength:&nbsp;
                                                    <em><b>{testTooltip ?.dataLength}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Number of Messages:&nbsp;
                                                    <em><b>{testTooltip ?.numberMessages}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Training Tokens:&nbsp;
                                                    <em><b>{testTooltip ?.trainingTokens}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Conversation MinLength:&nbsp;
                                                    <em><b>{testTooltip ?.conversationMinLength}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Conversation MaxLength:&nbsp;
                                                    <em><b>{testTooltip ?.conversationMaxLength}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Conversation MeanLength:&nbsp;
                                                    <em><b>{testTooltip ?.conversationMeanLength}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Conversation MedianLength:&nbsp;
                                                    <em><b>{testTooltip ?.conversationMedianLength}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Assistant MinLength:&nbsp;
                                                    <em><b>{testTooltip ?.assistantMinLength}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Assistant MaxLength:&nbsp;
                                                    <em><b>{testTooltip ?.assistantMaxLength}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Assistant MeanLength:&nbsp;
                                                    <em><b>{testTooltip ?.assistantMeanLength}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Assistant MedianLength:&nbsp;
                                                    <em><b>{testTooltip ?.assistantMedianLength}</b></em>
                                                </Typography>

                                                <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                                    Examples exceeding Limits:&nbsp;
                                                    <em><b>{testTooltip ?.examplesExceedingLimits}</b></em>
                                                </Typography>
                                                </>)}
                                            </>
                                        }
                                        >
                                            <IconButton disabled={!testTooltip} className="iconButton" sx={{
                                                fontSize: '16px',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '105%',
                                                transform: 'translateY(-50%)',
                                            }} size="small">
                                                <InfoIcon fontSize="extra-small" />
                                            </IconButton>
                                        </HtmlTooltip>
                                    </FormControl>

                                    <FormControl fullWidth sx={{
                                        m: 1, width: '35vw', '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: theme.palette.textfield.focused,
                                            }
                                        },
                                        '& .MuiInputLabel-outlined.Mui-focused': {
                                            color: theme.palette.textfield.focused,
                                        }
                                    }} size="small">
                                        <TextField id="outlined-basic" value={suffix} autoComplete="off" spellcheck="off" label="Enter Model Name" inputProps={{ maxLength: 40 }} onChange={handleSuffixChange} variant="outlined" size="small" />
                                    </FormControl>
                                    <FormControl component="fieldset" variant="standard">

                                    </FormControl>
                                    <Box sx={{ marginLeft: '20px', mt: 1, justifyContent: 'space-evenly' }}>
                                        <Typography noWrap={true} sx={{ ...(fonttypeStyle), color: theme.palette.font.main }}>Number of Epochs:</Typography>
                                        <Slider
                                            sx={{ width: '200px' }}
                                            value={epoch}
                                            min={0}
                                            max={20}
                                            step={1}
                                            onChange={handleEpochChange}
                                            color='error'
                                            size='small'
                                        />
                                        <TextField size='small' sx={{
                                            width: '100px', justifyContent: 'center', marginLeft: '100px', '& .MuiOutlinedInput-root': {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: theme.palette.textfield.focused,
                                                }
                                            },
                                            '& .MuiInputLabel-outlined.Mui-focused': {
                                                color: theme.palette.textfield.focused,
                                            }
                                        }} value={epoch} onChange={handleEpochChange} variant="outlined" />
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" disableElevation onClick={handleSubmit} disabled={isLoading || !isTestUploaded || !isSuffixEntered || !isTrainUploaded} component="label" sx={{ backgroundColor: '#DA291C', color: '#F0F0F0', fontFamily: 'poppins', fontWeight: '300', pointerEvents: 'auto', position: 'absolute', bottom: '15px', Top: '10px' }}>
                                    Train {isLoading ? <CircularProgress size={20} sx={{ marginLeft: 1 }} /> : <SettingsSuggestIcon fontSize='small' sx={{ marginLeft: 1 }} />}
                                </Button>

                                <HtmlTooltip placement="right" PopperProps={{
                                    modifiers: [
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, 0],
                                            },
                                        },
                                    ],
                                }} title={
                                    <React.Fragment>
                                        <Typography color="inherit" sx={{ fontSize: '12px' }}>
                                            Training Cost estimation:&nbsp;
                                            <em><b>{trainEstimation}</b></em>
                                        </Typography>
                                    </React.Fragment>
                                }
                                >
                                    <IconButton disabled={!trainEstimation} sx={{
                                        fontSize: '16px',
                                        position: 'absolute', bottom: '20px', left: '60%'
                                    }} size="small">
                                        <InfoIcon fontSize="extra-small" />
                                    </IconButton>
                                </HtmlTooltip>
                            </Box>
                        </>)}
            </Paper>
        </Box>
    );
}
