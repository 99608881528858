export const textfield = () => ({
    width: '85%'
});

export const bottomSection = () => ({
    position: 'absolute',
    bottom: '0',
    width: '96%',
    left: '2%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly'
})

export const button = () => ({
    backgroundColor: '#DA291C',
    color: '#F0F0F0',
    fontFamily: 'poppins',
    fontWeight: '300',
    pointerEvents: 'auto',
    '&:hover': {
        backgroundColor: '#B3B3B3',
    }
});

export const chat = () => ({
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    borderRadius: '10px',
});
